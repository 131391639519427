import { fetch_wrapper } from '../utility_logic/fetch_wrapper';
import { force_minimum_load } from '../utility_logic/minimum_loading';

const SET_AGENT_DATA = 'agent/setAgentData';
const SET_AGENT_FETCHING = 'agent/setAgentFetching';
const SET_TOTAL_STATISTICS = 'agent/setTotalStatistics';

const setAgentData = (agent_data: { [key: string]: string | number }) => {
    return {
        type: SET_AGENT_DATA,
        payload: agent_data,
    };
};

const setAgentFetching = (fetching: boolean) => {
    return {
        type: SET_AGENT_FETCHING,
        payload: fetching,
    };
};

const setTotalStatistics = (totalStatisticsData: { [key: string]: string | number }) => {
    return {
        type: SET_TOTAL_STATISTICS,
        payload: totalStatisticsData,
    };
};

export const getAgentData = (agentSlug: string | null) => async (dispatch: any) => {
    dispatch(setAgentFetching(true));
    const waitPromise = force_minimum_load(1000, 2000);
    await fetch_wrapper(`/get-agent-data`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ agent_slug: agentSlug ? agentSlug : '' }),
    })
        .then((response) => response.json())
        .then(async (agentData) => {
            await waitPromise;
            dispatch(setAgentData(agentData));
            dispatch(setAgentFetching(false));
        });
    return;
};

export const getTotalStatistics = () => async (dispatch: any) => {
    await fetch_wrapper(`/get-total-statistics-data`, {
        method: 'GET',
        credentials: 'include',
    })
        .then((response) => response.json())
        .then((totalStatisticsData) => {
            dispatch(setTotalStatistics(totalStatisticsData));
        });
    return;
};

const initialState = { agent: null, agentFetching: null, totalStatistics: null };

const titleDataReducer = (state = initialState, action: any) => {
    let newState;
    switch (action.type) {
        case SET_AGENT_DATA:
            newState = { ...state };
            newState.agent = action.payload;
            return newState;
        case SET_AGENT_FETCHING:
            newState = { ...state };
            newState.agentFetching = action.payload;
            return newState;
        case SET_TOTAL_STATISTICS:
            newState = { ...state };
            newState.totalStatistics = action.payload;
            return newState;
        default:
            return state;
    }
};

export default titleDataReducer;
