import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { fetch_wrapper } from '../../utility_logic/fetch_wrapper';

const PlanSelector = ({
    usingMobile,
    setPlanProductObj,
    stripePlanProducts,
}: {
    usingMobile: boolean;
    setPlanProductObj: React.Dispatch<
        React.SetStateAction<{
            [key: string]: any;
        }>
    >;
    stripePlanProducts: {
        [key: string]: any;
    }[];
}) => {
    const [planIndex, setPlanIndex] = useState<number>(0);

    useEffect(() => {
        if (stripePlanProducts.length) {
            setPlanProductObj(stripePlanProducts[0]);
        }
    }, [stripePlanProducts]);

    return (
        <>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: usingMobile ? 'space-between' : 'space-evenly',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                {stripePlanProducts.map((stripePlanProduct, i) => {
                    return (
                        <div
                            style={{
                                border: '1px solid',
                                borderColor: planIndex === i ? '#6C757D' : '#CED4DA',
                                borderRadius: '5px',
                                boxShadow: planIndex === i ? '0 2px 5px #6C757D' : '0 2px 10px #CED4DA',
                                cursor: 'default',
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: usingMobile ? '47%' : '40%',
                                boxSizing: 'border-box',
                                marginBottom: '3vh',
                                height: '15vh',
                            }}
                            onClick={() => {
                                setPlanProductObj(stripePlanProduct);
                                setPlanIndex(i);
                            }}
                            key={i}
                        >
                            <input type="checkbox" className="planCheckbox" checked={planIndex === i} readOnly={true} />
                            <label />

                            <div
                                style={{
                                    fontFamily: 'Raleway',
                                    fontSize: usingMobile ? '1.2rem' : '1.4rem',
                                    fontWeight: '600',
                                    lineHeight: '22px',
                                    color: '#495057',
                                }}
                            >
                                {stripePlanProduct.name}
                            </div>
                            <div style={{ height: '1vh' }}></div>
                            <div
                                style={{
                                    fontFamily: 'Raleway',
                                    fontSize: '1.5rem',
                                    fontWeight: '600',
                                    color: '#495057',
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                }}
                            >
                                <div style={{ marginBottom: '2px', marginRight: '3px' }}>
                                    ${stripePlanProduct.price.unit_amount / 100}{' '}
                                </div>
                                <div style={{ color: '#ADB5BD', fontSize: '1rem' }}>/ Month</div>
                            </div>
                        </div>
                    );
                })}

                <div
                    style={{
                        border: '1px solid',
                        borderColor: '#CED4DA',
                        borderRadius: '5px',
                        boxShadow: '0 2px 10px #CED4DA',
                        cursor: 'default',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: usingMobile ? '47%' : '40%',
                        opacity: '0.5',
                        boxSizing: 'border-box',
                        marginBottom: '3vh',
                        height: '15vh',
                    }}
                >
                    <div
                        style={{
                            fontFamily: 'Raleway',
                            fontSize: usingMobile ? '1.2rem' : '1.4rem',
                            fontWeight: '600',
                            lineHeight: '22px',
                            color: '#495057',
                        }}
                    >
                        More Plans
                    </div>
                    <div style={{ height: '1vh' }}></div>
                    <div
                        style={{
                            fontFamily: 'Raleway',
                            fontSize: usingMobile ? '1.3rem' : '1.5rem',
                            fontWeight: '600',
                            color: '#495057',
                            display: 'flex',
                            alignItems: 'flex-end',
                        }}
                    >
                        Coming Soon
                    </div>
                </div>
            </div>
        </>
    );
};

export default connect((state: RootState) => ({
    usingMobile: state.mobile.usingMobile,
}))(PlanSelector);
