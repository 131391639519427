import { connect } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { useEffect, useState } from 'react';
import InfoForm from '../../components/InfoForm';
import LoadingSpinner from '../../components/LoadingSpinnerContainer/loadingSpinner';
import { force_minimum_load } from '../../utility_logic/minimum_loading';
import { fetch_wrapper } from '../../utility_logic/fetch_wrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import { navigate_wrapper } from '../../utility_logic/navigate_wrapper';
import { setRegion } from '../../store/region';
import { extractAddressComponents } from '../../utility_logic/extract_address_components';
import { setCurrentStep, setMaxStep } from '../../store/step';
import { getRegionElectricUtils, getRegionGasUtils } from '../../store/utilities';
import clsx from 'clsx';

const plan_purchase_supported_regions: { [key: string]: boolean } = { TX: true, ON: false };

const SignupPage = ({ validSession, usingMobile }: { validSession: boolean; usingMobile: boolean }) => {
    // Form Values
    const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(null);
    const [addressValue, setAddressValue] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    // Check email is valid before submitting
    const [isValidEmail, setIsValidEmail] = useState<boolean>(false);

    // Know if form is fetching or if submit was successful
    const [updateUserSuccess, setUpdateUserSuccess] = useState<boolean>(false);
    const [updateUserInfoFetching, setUpdateUserInfoFetching] = useState<boolean>(false);

    // Display text if error in form submition
    const [errorText, setErrorText] = useState<string>('');

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Check for onboarding-form-information object in local storage
        const onboardingFormInformation = JSON.parse(sessionStorage.getItem('kergons-onboarding-form-information')!);
        if (!onboardingFormInformation) {
            sessionStorage.setItem(
                'kergons-onboarding-form-information',
                JSON.stringify({
                    name: null,
                    formatted_address: null,
                    extracted_address_components: null,
                    email: null,
                }),
            );
        }
    }, []);

    class GooglePlacesAutocomplete {
        private autocomplete: google.maps.places.Autocomplete;

        constructor(inputElement: HTMLInputElement) {
            const options = {
                componentRestrictions: { country: ['us', 'ca'] }, // Restrict to US and Canada
                types: ['address'], // Restrict to addresses only
            };
            this.autocomplete = new google.maps.places.Autocomplete(inputElement, options);
            this.setupListeners();
        }

        private setupListeners() {
            this.autocomplete.addListener('place_changed', () => {
                const place = this.autocomplete.getPlace();
                setAddressValue(place['formatted_address']!);
                setPlace(place);
            });
        }
    }

    useEffect(() => {
        const inputElement = document.getElementById('autocompleteInput') as HTMLInputElement;
        if (inputElement && validSession) {
            new GooglePlacesAutocomplete(inputElement);
        }
    }, [validSession]);

    useEffect(() => {
        const emailVerificationPattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;

        if (emailVerificationPattern.test(email)) {
            setIsValidEmail(true);
        } else {
            setIsValidEmail(false);
        }
    }, [email]);

    const signupFormSubmit = async () => {
        const waitPromise = force_minimum_load();
        setUpdateUserInfoFetching(true);
        setErrorText('');

        let region: string = '';

        for (let address_component of place!['address_components']!) {
            if (address_component.types.includes('administrative_area_level_1')) {
                region = address_component.short_name.toUpperCase();
            }
        }

        fetch_wrapper(`/update-user-info`, {
            method: 'POST',
            body: JSON.stringify({
                name: name,
                email: email,
                formatted_address: place!['formatted_address'],
                place_id: place!['place_id'],
                region: region,
            }),
            credentials: 'include',
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((errorData) => {
                        throw new Error(JSON.stringify(errorData));
                    });
                }
                return response.json();
            })
            .then(async (data) => {
                const onboardingFormInformation =
                    JSON.parse(sessionStorage.getItem('kergons-onboarding-form-information')!) || {};
                onboardingFormInformation.name = name;
                onboardingFormInformation.formatted_address = place!['formatted_address'];
                onboardingFormInformation.email = email;
                onboardingFormInformation.extracted_address_components = extractAddressComponents(place);

                sessionStorage.setItem(
                    'kergons-onboarding-form-information',
                    JSON.stringify(onboardingFormInformation),
                );

                dispatch(setRegion(region));
                dispatch(getRegionElectricUtils(region));
                dispatch(getRegionGasUtils(region));

                await waitPromise;

                setUpdateUserInfoFetching(false);

                setUpdateUserSuccess(true);

                setTimeout(() => {
                    if (region in plan_purchase_supported_regions && plan_purchase_supported_regions[region]) {
                        dispatch(setMaxStep(2));
                        dispatch(setCurrentStep(2));
                        navigate_wrapper(navigate, location, '/planselect');
                    } else {
                        dispatch(setMaxStep(3));
                        dispatch(setCurrentStep(3));
                        navigate_wrapper(navigate, location, '/electric');
                    }
                }, 2000);
            })
            .catch(async (error) => {
                await waitPromise;
                setUpdateUserInfoFetching(false);
                setErrorText(JSON.parse(error.message).message);
            });
    };

    return (
        <>
            <>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: usingMobile ? '18vh' : '',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            fontFamily: 'Raleway, sans-serif',
                            fontWeight: '600',
                            fontSize: usingMobile ? '2.25rem' : '3rem',
                            lineHeight: usingMobile ? '44px' : '3.5rem',
                            color: '#212529',
                            height: usingMobile ? '20vh' : '',
                        }}
                    >
                        SIGN UP
                    </div>

                    <div style={{ height: usingMobile ? '3vh' : '2vh' }}></div>

                    <div
                        style={{
                            fontFamily: 'AmericanTypewriter',
                            fontWeight: '400',
                            fontSize: '1.125rem',
                            lineHeight: '26px',
                            height: usingMobile ? '11.5vh' : '',
                        }}
                    >
                        Provide your information and we’ll quickly let you know if you’re optimizing your home energy
                        expenditures.
                    </div>
                </div>

                <form
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: usingMobile ? 'space-between' : 'flex-start',
                        alignItems: 'flex-start',
                        width: '100%',
                        marginTop: usingMobile ? '6vh' : '5vh',
                        marginBottom: '0px',
                        height: usingMobile ? '90vh' : '',
                    }}
                    autoComplete="off"
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: usingMobile ? '41vh' : '',
                            justifyContent: 'space-between',
                        }}
                    >
                        <label
                            style={{
                                fontFamily: 'Raleway',
                                fontWeight: '600',
                                fontSize: '1.25rem',
                                lineHeight: '36px',
                            }}
                        >
                            Personal Information
                        </label>

                        <div style={{ height: '3vh' }}></div>

                        <InfoForm
                            customerName={name}
                            setCustomerName={setName}
                            addressValue={addressValue}
                            setAddressValue={setAddressValue}
                            email={email}
                            setEmail={setEmail}
                            place={place}
                            isValidEmail={isValidEmail}
                        ></InfoForm>
                    </div>

                    <div style={{ height: '4vh' }}></div>

                    <button
                        type="submit"
                        className={clsx('button submit', {
                            active: place && name && isValidEmail,
                            loading: updateUserInfoFetching || updateUserSuccess,
                            'mobile mobile-full': usingMobile,
                        })}
                        disabled={!place || !name || updateUserInfoFetching || !isValidEmail || updateUserSuccess}
                        onClick={() => signupFormSubmit()}
                    >
                        {updateUserSuccess ? (
                            <svg
                                width="50"
                                height="50"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M5 12l5 5L20 7"
                                    stroke="#212529"
                                    strokeWidth="2"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="checkmark-path"
                                />
                            </svg>
                        ) : updateUserInfoFetching ? (
                            ''
                        ) : (
                            'NEXT'
                        )}

                        <LoadingSpinner
                            active={updateUserInfoFetching && !updateUserSuccess}
                            usingMobile={usingMobile}
                            large={false}
                        ></LoadingSpinner>
                    </button>

                    {errorText && (
                        <>
                            <div
                                style={{
                                    color: 'red',
                                    fontFamily: 'Raleway',
                                    fontSize: '0.875rem',
                                    width: '100%',
                                    height: '2vh',
                                    fontWeight: '400',
                                }}
                            >
                                {errorText}
                            </div>
                        </>
                    )}
                </form>
            </>
        </>
    );
};

export default connect((state: RootState) => ({
    validSession: state.session.validSession,
    usingMobile: state.mobile.usingMobile,
}))(SignupPage);
