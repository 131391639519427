import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { Elements } from '@stripe/react-stripe-js';
import CustomerInfoForm from './CustomerInfoForm';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { fetch_wrapper } from '../../utility_logic/fetch_wrapper';
import PaymentConfirmationForm from './PaymentConfirmationForm';
import LoadingBox from '../../components/LoadingBox';

const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
let stripePromise: Stripe | PromiseLike<Stripe | null> | null;

stripePromise = loadStripe(stripePublishableKey || '');

const CustomerFormPage = ({ usingMobile }: { usingMobile: boolean }) => {
    const [clientSecret, setClientSecret] = useState<string>('');

    const [pageIndex, setPageIndex] = useState<number>(0);

    const [planProductObj, setPlanProductObj] = useState<{ [key: string]: any }>({});
    const [customerId, setCustomerId] = useState<string>('');
    const [stripePlanProducts, setStripePlanProducts] = useState<{ [key: string]: any }[]>([]);

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        fetch_wrapper(`/create-setup-intent`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                setClientSecret(data.client_secret);
            })
            .catch((error) => console.log('error', error));

        fetch_wrapper(`/get-stripe-plans`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((response) => response.json())
            .then((data) => {
                setStripePlanProducts(data.stripe_plans);
            });

        setTimeout(() => {
            setLoading(false);
        }, 1500);
    }, []);

    return (
        <>
            {loading ? (
                <LoadingBox usingMobile={usingMobile} inSteps={false}></LoadingBox>
            ) : (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: usingMobile ? '15vh' : '',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                fontFamily: 'Raleway, sans-serif',
                                fontWeight: '600',
                                fontSize: usingMobile ? '2.25rem' : '3rem',
                                lineHeight: usingMobile ? '44px' : '3.5rem',
                                color: '#212529',
                                height: usingMobile ? '20vh' : '',
                            }}
                        >
                            CHOOSE A PLAN
                        </div>

                        <div style={{ height: usingMobile ? '3vh' : '2vh' }}></div>

                        <div
                            style={{
                                fontFamily: 'AmericanTypewriter',
                                fontWeight: '400',
                                fontSize: '1.125rem',
                                lineHeight: '26px',
                                height: usingMobile ? '8.5vh' : '',
                            }}
                        >
                            Provide your information and we’ll quickly let you know if you’re optimizing your home
                            energy expenditures.
                        </div>
                    </div>
                    {clientSecret && (
                        <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
                            {pageIndex === 0 && (
                                <>
                                    <CustomerInfoForm
                                        setPageIndex={setPageIndex}
                                        setPlanProductObj={setPlanProductObj}
                                        setCustomerId={setCustomerId}
                                        stripePlanProducts={stripePlanProducts}
                                        extracted_address_components={
                                            JSON.parse(sessionStorage.getItem('kergons-onboarding-form-information')!)
                                                ?.extracted_address_components
                                        }
                                    ></CustomerInfoForm>
                                </>
                            )}
                            {pageIndex === 1 && (
                                <PaymentConfirmationForm
                                    planProductObj={planProductObj}
                                    customerId={customerId}
                                ></PaymentConfirmationForm>
                            )}
                        </Elements>
                    )}
                </>
            )}
        </>
    );
};

export default connect((state: RootState) => ({
    usingMobile: state.mobile.usingMobile,
}))(CustomerFormPage);
