import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { Utility } from '../../CustomTypings';

const InfoForm = ({
    usingMobile,
    customerName,
    setCustomerName,
    addressValue,
    setAddressValue,
    email,
    setEmail,
    place,
    isValidEmail,
}: {
    usingMobile: boolean;
    customerName: string;
    setCustomerName: React.Dispatch<React.SetStateAction<string>>;
    addressValue: string;
    setAddressValue: React.Dispatch<React.SetStateAction<string>>;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    place: google.maps.places.PlaceResult | null;
    isValidEmail: boolean;
}) => {
    const [nameFocused, setNameFocused] = useState<boolean>(false);
    const [addressFocused, setAddressFocused] = useState<boolean>(false);
    const [emailFocused, setEmailFocused] = useState<boolean>(false);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: usingMobile ? '33vh' : '',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        justifyContent: 'flex-end',
                    }}
                >
                    <div
                        style={{
                            fontFamily: 'Raleway',
                            fontSize: '0.875rem',
                            fontWeight: '500',
                            marginBottom: '1vh',
                            color: '#495057',
                        }}
                    >
                        Name
                    </div>
                    <input
                        autoComplete="off"
                        type="text"
                        placeholder="Complete Name"
                        className="basic_input"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        onFocus={() => setNameFocused(true)}
                        onBlur={() => setNameFocused(false)}
                        style={{
                            height: '6vh',
                            maxHeight: '46px',
                            width: '100%',
                            border: '1px solid',
                            borderRadius: '4px',
                            padding: '13px',
                            fontSize: '1rem',
                            lineHeight: '22px',
                            fontFamily: 'Roboto',
                            fontWeight: '400',
                            borderColor: nameFocused || customerName ? '#6C757D' : '#CED4DA',
                            color: '#212529',
                            transition: 'border-color 0.3s',
                        }}
                    />
                </div>

                <div style={{ height: usingMobile ? '4vh' : '3vh' }}></div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        justifyContent: 'flex-end',
                    }}
                >
                    <div
                        style={{
                            fontFamily: 'Raleway',
                            fontSize: '0.875rem',
                            fontWeight: '500',
                            marginBottom: '1vh',
                            color: '#495057',
                        }}
                    >
                        Email
                    </div>
                    <input
                        autoComplete="off"
                        type="email"
                        placeholder="Email"
                        className="basic_input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onFocus={() => setEmailFocused(true)}
                        onBlur={() => setEmailFocused(false)}
                        required={true}
                        style={{
                            height: '6vh',
                            maxHeight: '46px',
                            width: '100%',
                            border: '1px solid',
                            borderRadius: '4px',
                            padding: '13px',
                            fontSize: '1rem',
                            lineHeight: '22px',
                            fontFamily: 'Roboto',
                            fontWeight: '400',
                            borderColor:
                                !emailFocused && !isValidEmail && email
                                    ? '#D9001A'
                                    : emailFocused || email
                                    ? '#6C757D'
                                    : '#CED4DA',
                            color: '#212529',
                            transition: 'border-color 0.3s',
                        }}
                    />
                </div>

                <div style={{ height: usingMobile ? '4vh' : '3vh' }}></div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            fontFamily: 'Raleway',
                            fontSize: '0.875rem',
                            fontWeight: '500',
                            marginBottom: '1vh',
                            color: '#495057',
                        }}
                    >
                        Address
                    </div>
                    <input
                        autoComplete="off"
                        type="text"
                        placeholder="Address"
                        className="basic_input"
                        id="autocompleteInput"
                        value={addressValue}
                        onChange={(e) => setAddressValue(e.target.value)}
                        onFocus={() => setAddressFocused(true)}
                        onBlur={() => setAddressFocused(false)}
                        style={{
                            height: '6vh',
                            maxHeight: '46px',
                            width: '100%',
                            border: '1px solid',
                            borderRadius: '4px',
                            padding: '13px',
                            fontSize: '1rem',
                            lineHeight: '22px',
                            fontFamily: 'Roboto',
                            fontWeight: '400',
                            borderColor: place || addressFocused || addressValue ? '#6C757D' : '#CED4DA',
                            color: '#212529',
                            transition: 'border-color 0.3s',
                            position: 'relative',
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default connect((state: RootState) => ({
    usingMobile: state.mobile.usingMobile,
}))(InfoForm);
