import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import * as sessionActions from '../../store/session';
import { useLocation, useNavigate } from 'react-router-dom';
import PhoneNumberForm from '../../components/PhoneNumberForm';
import VerificationForm from '../../components/VerificationForm';
import { fetch_wrapper } from '../../utility_logic/fetch_wrapper';
import * as stepActions from '../../store/step';
import { navigate_wrapper } from '../../utility_logic/navigate_wrapper';

const resendSeconds = 30;

const LogInSignUpForm = ({ validSession, usingMobile }: { validSession: boolean; usingMobile: boolean }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const [secondsLeft, setSecondsLeft] = useState(resendSeconds);
    const [verifSent, setVerifSent] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberSent, setPhoneNumberSent] = useState(false);

    const handleLogout = async () => {
        dispatch(stepActions.logOutSteps());
        await fetch_wrapper(`/log-out-session`, {
            method: 'GET',
            credentials: 'include',
        });
        dispatch(sessionActions.sessionInvalidated());
    };

    const sendVerifText = (waitPromise: Promise<unknown> | null) => {
        fetch_wrapper(`/text-verification`, {
            method: 'POST',
            body: JSON.stringify({ phone_number: phoneNumber }),
        })
            .then((response) => response.json())
            .then(async (data) => {
                if (waitPromise) {
                    await waitPromise;
                }
                if (data.status === 'success') {
                    setVerifSent(true);
                    setSecondsLeft(resendSeconds);
                } else {
                    setPhoneNumberSent(false);
                }
                return data;
            });
    };

    useEffect(() => {
        if (!secondsLeft) return;

        const intervalId = setInterval(() => {
            setSecondsLeft(secondsLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [secondsLeft]);

    return (
        <>
            {validSession && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button
                        variant="outlined"
                        style={{ fontSize: '1.125rem', marginTop: '20px' }}
                        onClick={() => navigate_wrapper(navigate, location, '/confirm')}
                    >
                        Confirm My Data
                    </Button>
                    <Button
                        variant="outlined"
                        style={{ fontSize: '1.125rem', marginTop: '20px' }}
                        onClick={() => handleLogout()}
                    >
                        LogOut
                    </Button>
                </div>
            )}

            {!validSession && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: usingMobile ? '34.5vh' : '',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div
                            style={{
                                fontFamily: 'Raleway, sans-serif',
                                fontWeight: '600',
                                fontSize: usingMobile ? '2.25rem' : '3rem',
                                lineHeight: usingMobile ? '44px' : '3.5rem',
                                color: '#212529',
                                height: usingMobile ? '20vh' : '',
                            }}
                        >
                            SEE HOW MUCH <br />
                            <span>YOU CAN SAVE ON YOUR HOME ENERGY</span>
                        </div>

                        <div style={{ height: usingMobile ? '3vh' : '2vh' }}></div>

                        <div
                            style={{
                                fontFamily: 'AmericanTypewriter, sans-serif',
                                fontSize: '1.125rem',
                                lineHeight: '26px',
                                height: usingMobile ? '11.5vh' : '',
                            }}
                        >
                            Simply provide your phone number and we’ll quickly let you know if you’re optimizing your
                            home energy expenditures.
                        </div>
                    </div>

                    {!verifSent && (
                        <PhoneNumberForm
                            phoneNumber={phoneNumber}
                            setPhoneNumber={setPhoneNumber}
                            phoneNumberSent={phoneNumberSent}
                            setPhoneNumberSent={setPhoneNumberSent}
                            sendVerifText={sendVerifText}
                            usingMobile={usingMobile}
                        />
                    )}
                    {verifSent && (
                        <VerificationForm
                            secondsLeft={secondsLeft}
                            phoneNumber={phoneNumber}
                            sendVerifText={sendVerifText}
                            setSecondsLeft={setSecondsLeft}
                            resendSeconds={resendSeconds}
                            usingMobile={usingMobile}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default connect((state: RootState) => ({
    validSession: state.session.validSession,
    usingMobile: state.mobile.usingMobile,
}))(LogInSignUpForm);
