import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import SignaturePad from 'react-signature-pad-wrapper';
import LoadingSpinner from '../LoadingSpinnerContainer/loadingSpinner';

const signatureFontStyles = ['Alex Brush', 'Great Vibes', 'Dancing Script'];

const SignatureForm = ({
    usingMobile,
    customerName,
    canvasRef,
    drawing,
    setDrawing,
    signatureTextRef,
    spanRef,
}: {
    usingMobile: boolean;
    customerName: string;
    canvasRef: React.MutableRefObject<SignaturePad | null>;
    drawing: boolean;
    setDrawing: React.Dispatch<React.SetStateAction<boolean>>;
    signatureTextRef: React.MutableRefObject<HTMLInputElement | null>;
    spanRef: React.MutableRefObject<HTMLSpanElement | null>;
}) => {
    const canvasContainer = useRef<HTMLDivElement | null>(null);
    const [canvasContainerWidth, setCanvasContainerWidth] = useState<number>(0);
    const [signatureObj, setSignatureObj] = useState<{ [key: string]: any }>({
        signatureText: customerName,
        signatureStyle: 0,
    });
    const [signatureTextFocused, setSignatureTextFocused] = useState<boolean>(false);
    const [signatureInfoHovered, setSignatureInfoHovered] = useState<boolean>(false);
    const [sigPadLoading, setSigPadLoading] = useState<boolean>(true);

    useEffect(() => {
        setSigPadLoading(true);
        setTimeout(() => {
            if (canvasContainer.current) {
                setCanvasContainerWidth(canvasContainer.current.offsetWidth);
                setSigPadLoading(false);
            }
        }, 500);
    }, []);

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%' }}>
                <label
                    style={{
                        fontFamily: 'Raleway',
                        fontWeight: '600',
                        fontSize: '1.25rem',
                        lineHeight: '36px',
                    }}
                >
                    Identity Information
                </label>
                <div
                    style={{
                        marginLeft: '12px',
                        borderRadius: '50%',
                        padding: '13px',
                        width: '7px',
                        height: '6px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '1.05rem',
                        backgroundColor: '#CED4DA',
                        color: '#343A40',
                        cursor: 'default',
                        maxWidth: '5%',
                    }}
                    onMouseEnter={() => {
                        if (!usingMobile) {
                            setSignatureInfoHovered(true);
                        }
                    }}
                    onMouseLeave={() => {
                        if (!usingMobile) {
                            setSignatureInfoHovered(false);
                        }
                    }}
                    onClick={() => {
                        if (usingMobile) {
                            setSignatureInfoHovered(!signatureInfoHovered);
                        }
                    }}
                >
                    ?
                </div>
                {usingMobile && (
                    <div
                        style={{
                            height: signatureInfoHovered ? '100vh' : '100vh',
                            width: '100vw',
                            zIndex: signatureInfoHovered ? '998' : '',
                            position: 'fixed',
                            bottom: '0',
                            left: '0',
                            backgroundColor: '#00000033',
                            backdropFilter: signatureInfoHovered ? 'blur(2px)' : 'blur(0px)',
                            opacity: signatureInfoHovered ? '1' : '0',
                            transition: 'opacity 0.25s ease-in-out, backdrop-filter 0.25s ease-in-out',
                            pointerEvents: signatureInfoHovered ? 'auto' : 'none',
                        }}
                        onClick={() => setSignatureInfoHovered(false)}
                    ></div>
                )}
                <div
                    className={`fade-box ${signatureInfoHovered ? 'visible' : ''}`}
                    style={{
                        top: usingMobile ? '50%' : '-55%',
                        bottom: usingMobile ? '' : '',
                        left: usingMobile ? '12.5%' : '47.5%',
                        width: usingMobile ? 'calc(75% - 32px)' : '40%',
                        maxWidth: usingMobile ? '' : '50%',
                        zIndex: '999',
                        padding: usingMobile ? '10px 15px 10px 15px' : '10px 5px 10px 15px',
                    }}
                    onClick={() => setSignatureInfoHovered(false)}
                >
                    <div
                        style={{
                            width: '90%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            height: '32px',
                            position: 'absolute',
                            top: '4%',
                        }}
                    >
                        <img
                            src="/close.svg"
                            alt=""
                            width={'32px'}
                            height={'32px'}
                            onClick={() => {
                                setSignatureInfoHovered(false);
                            }}
                            style={{ cursor: 'pointer' }}
                        ></img>
                    </div>
                    <span
                        style={{
                            fontFamily: 'Raleway',
                            fontWeight: '600',
                            fontSize: '1.5rem',
                            lineHeight: '36px',
                            marginBottom: '2vh',
                        }}
                    >
                        Signature
                    </span>
                    Draw or type your name to create the signature that will be applied to the Letter of Authorization
                    for your TDSP and the Agency Authorization Letter for every REP we enroll you with.
                </div>
            </div>

            <div style={{ height: '3vh' }}></div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '15px',
                        width: usingMobile ? '100%' : '85%',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            width: usingMobile ? '45%' : '40%',
                            justifyContent: 'space-between',
                            marginLeft: '10px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                position: 'relative',
                            }}
                            onClick={() => {
                                setDrawing(!drawing);
                            }}
                        >
                            <div
                                style={{
                                    height: '22px',
                                    width: '22px',
                                    margin: '0px',
                                    border: '1px solid #1C1C1A',
                                    borderRadius: '50%',
                                    backgroundColor: drawing ? '#1C1C1A' : '#FFFFFF',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {drawing && (
                                    <div
                                        style={{
                                            height: '12px',
                                            width: '12px',
                                            margin: '0px',
                                            borderRadius: '50%',
                                            backgroundColor: '#FFFFFF',
                                        }}
                                    ></div>
                                )}
                            </div>
                            <label
                                style={{
                                    fontFamily: 'Raleway',
                                    fontSize: '0.875rem',
                                    lineHeight: '16px',
                                    fontWeight: '500',
                                    textAlign: 'center',
                                    color: '#1C1C1A',
                                    marginLeft: '12px',
                                }}
                            >
                                Draw
                            </label>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                position: 'relative',
                            }}
                            onClick={() => {
                                setDrawing(!drawing);
                            }}
                        >
                            <div
                                style={{
                                    height: '22px',
                                    width: '22px',
                                    margin: '0px',
                                    border: '1px solid #1C1C1A',
                                    borderRadius: '50%',
                                    backgroundColor: !drawing ? '#1C1C1A' : '#FFFFFF',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {!drawing && (
                                    <div
                                        style={{
                                            height: '12px',
                                            width: '12px',
                                            margin: '0px',
                                            borderRadius: '50%',
                                            backgroundColor: '#FFFFFF',
                                        }}
                                    ></div>
                                )}
                            </div>
                            <label
                                style={{
                                    fontFamily: 'Raleway',
                                    fontSize: '0.875rem',
                                    lineHeight: '16px',
                                    fontWeight: '500',
                                    textAlign: 'center',
                                    color: '#1C1C1A',
                                    marginLeft: '12px',
                                }}
                            >
                                Type
                            </label>
                        </div>
                    </div>

                    <div style={{ height: '1.5vh' }}></div>

                    <div
                        ref={canvasContainer}
                        style={{
                            border: '2px solid #6C757D',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '15px',
                            overflow: 'hidden',
                        }}
                    >
                        {drawing && (
                            <>
                                {!sigPadLoading ? (
                                    <SignaturePad
                                        height={window.innerHeight * 0.15}
                                        width={canvasContainerWidth}
                                        ref={canvasRef}
                                    />
                                ) : (
                                    <div
                                        style={{
                                            height: Math.floor(window.innerHeight * 0.15),
                                            width: '100%',
                                        }}
                                    ></div>
                                )}
                                <div
                                    style={{
                                        border: '1px solid #CED4DA',
                                        width: '95%',
                                    }}
                                ></div>
                                <div
                                    style={{
                                        width: '90%',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                    }}
                                >
                                    <button
                                        style={{
                                            fontFamily: 'Raleway',
                                            fontSize: '1.5rem',
                                            fontWeight: '600',
                                            lineHeight: '22px',
                                            background: 'white',
                                            marginTop: '1.5vh',
                                            marginBottom: '1.5vh',
                                            color: '#495057',
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (canvasRef.current) {
                                                canvasRef.current.clear();
                                            }
                                        }}
                                    >
                                        Clear
                                    </button>
                                </div>
                            </>
                        )}
                        {!drawing && (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: usingMobile ? '90%' : '95%',
                                    }}
                                >
                                    <div style={{ height: '2.5vh' }}></div>

                                    <div
                                        style={{
                                            fontFamily: 'Raleway',
                                            fontSize: '0.875rem',
                                            fontWeight: '500',
                                            color: '#495057',
                                        }}
                                    >
                                        Signature Text
                                    </div>

                                    <div style={{ height: '2vh' }}></div>

                                    <input
                                        autoComplete="off"
                                        type="text"
                                        placeholder={signatureObj.signatureText}
                                        className="basic_input"
                                        id="autocompleteInput"
                                        value={signatureObj.signatureText}
                                        onChange={(e) => {
                                            setSignatureObj({ ...signatureObj, signatureText: e.target.value });
                                        }}
                                        onFocus={() => setSignatureTextFocused(true)}
                                        onBlur={() => setSignatureTextFocused(false)}
                                        style={{
                                            height: '6vh',
                                            maxHeight: '46px',
                                            border: '1px solid',
                                            borderRadius: '10px',
                                            padding: '13px',
                                            fontSize: '1rem',
                                            lineHeight: '22px',
                                            fontFamily: 'Roboto',
                                            fontWeight: '400',
                                            borderColor:
                                                signatureTextFocused || signatureObj.signatureText
                                                    ? '#6C757D'
                                                    : '#CED4DA',
                                            color: '#212529',
                                            transition: 'border-color 0.3s',
                                            marginLeft: usingMobile ? '' : '7%',
                                            marginRight: usingMobile ? '' : '7%',
                                        }}
                                    />

                                    <div style={{ height: '4vh' }}></div>

                                    <div
                                        style={{
                                            fontFamily: 'Raleway',
                                            fontSize: '0.875rem',
                                            fontWeight: '500',
                                            color: '#495057',
                                        }}
                                    >
                                        Style Options
                                    </div>

                                    <div style={{ height: '1.5vh' }}></div>

                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'space-evenly',
                                            alignItems: 'center',
                                            flexWrap: usingMobile ? 'wrap' : 'nowrap',
                                            height: usingMobile ? '6.75rem' : '',
                                        }}
                                    >
                                        {signatureFontStyles.map((font, index) => {
                                            return (
                                                <div
                                                    style={{
                                                        width: '50%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                    }}
                                                    key={'fontStyles' + index.toString()}
                                                >
                                                    <div
                                                        style={{
                                                            fontFamily: font,
                                                            fontSize:
                                                                signatureObj.signatureStyle === index
                                                                    ? '2.5rem'
                                                                    : '2.25rem',
                                                            fontWeight: '400',
                                                            cursor: 'pointer',
                                                            textDecoration:
                                                                signatureObj.signatureStyle === index
                                                                    ? 'underline'
                                                                    : '',
                                                            textUnderlineOffset: '0.3rem',
                                                            height: '3rem',
                                                        }}
                                                        onClick={(e) => {
                                                            setSignatureObj({
                                                                ...signatureObj,
                                                                signatureStyle: index,
                                                                signatureFontFamily: window.getComputedStyle(
                                                                    e.currentTarget,
                                                                ).fontFamily,
                                                            });
                                                        }}
                                                    >
                                                        Kergons
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>

                                    <div style={{ height: usingMobile ? '' : '4vh' }}></div>

                                    <div
                                        style={{
                                            fontFamily: 'Raleway',
                                            fontSize: '0.875rem',
                                            fontWeight: '500',
                                            color: '#495057',
                                        }}
                                    >
                                        Preview
                                    </div>

                                    <div style={{ height: '1.5vh' }}></div>

                                    <input
                                        defaultValue={signatureObj.signatureText}
                                        value={signatureObj.signatureText}
                                        ref={signatureTextRef}
                                        style={{
                                            height: '6vh',
                                            maxHeight: usingMobile ? '' : '46px',
                                            border: '1px solid',
                                            borderRadius: '10px',
                                            padding: '13px',
                                            fontSize: '2rem',
                                            lineHeight: '2rem',
                                            fontFamily: signatureObj.signatureFontFamily || signatureFontStyles[0],
                                            fontWeight: '400',
                                            borderColor: '#CED4DA',
                                            color: '#212529',
                                            transition: 'border-color 0.3s',
                                            marginLeft: '7%',
                                            marginRight: '7%',
                                            pointerEvents: 'none',
                                            marginBottom: '1vh',
                                        }}
                                    />
                                    <span
                                        ref={spanRef}
                                        style={{
                                            position: 'absolute',
                                            visibility: 'hidden',
                                            whiteSpace: 'pre',
                                            fontFamily: 'inherit',
                                            fontSize: 'inherit',
                                            padding: '0',
                                            margin: '0',
                                            border: 'none',
                                        }}
                                    ></span>

                                    <div style={{ height: '2vh' }}></div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            <div style={{ height: '2vh' }}></div>

            <div
                style={{
                    fontFamily: 'Raleway',
                    fontWeight: '400',
                    fontSize: '0.95rem',
                    lineHeight: '26px',
                    width: usingMobile ? '100%' : '87%',
                }}
            >
                Your signature will be recreated on documents we submit on your behalf to your utility company and the
                retail electricity providers we enroll you with. Copies of these documents will be available for your
                review in the member portal.
            </div>
        </>
    );
};

export default connect((state: RootState) => ({
    usingMobile: state.mobile.usingMobile,
}))(SignatureForm);
