import { useEffect, useState } from 'react';

const LoadingText = ({ active, loadingText, usingMobile }: { active: boolean; loadingText: string; usingMobile: boolean }) => {
    const [periodCounter, setPeriodCounter] = useState<number>(0);

    useEffect(() => {
        const interval1 = setInterval(() => {
            setPeriodCounter((v) => (v === 3 ? 0 : v + 1));
        }, 300);

        return () => {
            clearInterval(interval1);
        };
    }, []);

    return (
        <>
            {active && (
                <div
                    style={{
                        textAlign: 'center',
                        fontSize: usingMobile ? '0.9375rem' : '1.125rem',
                        lineHeight: '26px',
                        fontWeight: '400',
                        fontFamily: 'AmericanTypewriter',
                        width: usingMobile ? '' : '65%',
                    }}
                >
                    {loadingText}
                    <span style={{ visibility: periodCounter > 0 ? 'visible' : 'hidden' }}>.</span>
                    <span style={{ visibility: periodCounter > 1 ? 'visible' : 'hidden' }}>.</span>
                    <span style={{ visibility: periodCounter > 2 ? 'visible' : 'hidden' }}>.</span>
                </div>
            )}
        </>
    );
};

export default LoadingText;
