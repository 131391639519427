const environment = process.env.REACT_APP_ENVIRONMENT;
const io_backend_url = process.env.REACT_APP_IO_BACKEND_URL;
const ca_backend_url = process.env.REACT_APP_CA_BACKEND_URL;
const com_backend_url = process.env.REACT_APP_COM_BACKEND_URL;

export const fetch_wrapper = async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
    let backend_url = '';

    const hostname = window.location.hostname;

    if (hostname.endsWith('secure.wattsons.io')) {
        backend_url = io_backend_url!;
    } else if (hostname.endsWith('secure.wattsons.ca')) {
        backend_url = ca_backend_url!;
    } else if (hostname.endsWith('secure.kergons.com')) {
        backend_url = com_backend_url!;
    } else if (environment === 'dev') {
        backend_url = ca_backend_url!;
    }

    const url: RequestInfo = backend_url! + input;

    return fetch(url, init);
};
