import { TextField } from '@mui/material';
import { FormEvent, MutableRefObject, useEffect, useRef, useState } from 'react';
import SingleNumberInput from './SingleNumberInput';

type props = {
    verificationCode: string;
    setVerificationCode: React.Dispatch<React.SetStateAction<string>>;
    verifSuccess: boolean | null;
    resetAfterFailure: boolean;
    setResetAfterFailure: React.Dispatch<React.SetStateAction<boolean>>;
    usingMobile: boolean;
};

const VerificationInput = ({
    verificationCode,
    setVerificationCode,
    verifSuccess,
    resetAfterFailure,
    setResetAfterFailure,
    usingMobile,
}: props) => {
    const input1Ref = useRef<HTMLInputElement>(null);
    const input2Ref = useRef<HTMLInputElement>(null);
    const input3Ref = useRef<HTMLInputElement>(null);
    const input4Ref = useRef<HTMLInputElement>(null);
    const input5Ref = useRef<HTMLInputElement>(null);

    const [focusIndex, setFocusIndex] = useState<number | null>(null); // Track which input is focused

    const updateverificationCode = () => {
        const value1 = input1Ref.current?.value || '';
        const value2 = input2Ref.current?.value || '';
        const value3 = input3Ref.current?.value || '';
        const value4 = input4Ref.current?.value || '';
        const value5 = input5Ref.current?.value || '';
        setVerificationCode(value1 + value2 + value3 + value4 + value5); // Combine the values
    };

    const handleInput = (
        e: React.ChangeEvent<HTMLInputElement>,
        nextInputRef: React.RefObject<HTMLInputElement> | null,
    ) => {
        const { value } = e.target as HTMLInputElement;

        if (value.length === 1) {
            e.target.value = value;
            if (nextInputRef) {
                nextInputRef.current?.focus(); // Move focus to the next input
            }
        }

        updateverificationCode();
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const paste = e.clipboardData.getData('text');
        if (paste.length === 5 && !isNaN(Number(paste))) {
            // Set the values of both inputs
            if (input1Ref.current && input2Ref.current && input3Ref.current && input4Ref.current && input5Ref.current) {
                input1Ref.current.value = paste[0];
                input2Ref.current.value = paste[1];
                input3Ref.current.value = paste[2];
                input4Ref.current.value = paste[3];
                input5Ref.current.value = paste[4];
                input1Ref.current.blur();
                input2Ref.current.blur();
                input3Ref.current.blur();
                input4Ref.current.blur();
                input5Ref.current.blur();
            }

            setVerificationCode(paste);
        } else if (!isNaN(Number(paste))) {
            updateverificationCode();
        }
    };

    useEffect(() => {
        if (!verificationCode) {
            if (input1Ref.current && input2Ref.current && input3Ref.current && input4Ref.current && input5Ref.current) {
                input1Ref.current.value = '';
                input2Ref.current.value = '';
                input3Ref.current.value = '';
                input4Ref.current.value = '';
                input5Ref.current.value = '';
            }
        }
    }, [verificationCode]);

    const handleFocus = (index: number, inputRef: React.RefObject<HTMLInputElement>) => {
        if (
            verifSuccess === false &&
            !resetAfterFailure &&
            input1Ref.current &&
            input2Ref.current &&
            input3Ref.current &&
            input4Ref.current &&
            input5Ref.current
        ) {
            input1Ref.current.value = '';
            input2Ref.current.value = '';
            input3Ref.current.value = '';
            input4Ref.current.value = '';
            input5Ref.current.value = '';
            setResetAfterFailure(true);
        }
        setFocusIndex(index); // Set the focused input index
        if (inputRef.current) {
            const length = inputRef.current.value.length;
            inputRef.current.setSelectionRange(length, length);
        }
    };

    const handleBlur = () => {
        setFocusIndex(null); // Reset when input loses focus
    };

    return (
        <div
            style={{
                width: usingMobile ? '80%' : '60%',
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: usingMobile ? '' : '230px',
            }}
        >
            <SingleNumberInput
                inputRef={input1Ref}
                nextInputRef={input2Ref}
                handleInput={handleInput}
                handlePaste={handlePaste}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                focusIndex={focusIndex}
                usingMobile={usingMobile}
                index={1}
            />

            <SingleNumberInput
                inputRef={input2Ref}
                nextInputRef={input3Ref}
                handleInput={handleInput}
                handlePaste={handlePaste}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                focusIndex={focusIndex}
                usingMobile={usingMobile}
                index={2}
            />

            <SingleNumberInput
                inputRef={input3Ref}
                nextInputRef={input4Ref}
                handleInput={handleInput}
                handlePaste={handlePaste}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                focusIndex={focusIndex}
                usingMobile={usingMobile}
                index={3}
            />

            <SingleNumberInput
                inputRef={input4Ref}
                nextInputRef={input5Ref}
                handleInput={handleInput}
                handlePaste={handlePaste}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                focusIndex={focusIndex}
                usingMobile={usingMobile}
                index={4}
            />

            <SingleNumberInput
                inputRef={input5Ref}
                nextInputRef={null}
                handleInput={handleInput}
                handlePaste={handlePaste}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                focusIndex={focusIndex}
                usingMobile={usingMobile}
                index={5}
            />
        </div>
    );
};

export default VerificationInput;
