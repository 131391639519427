type props = {
    inputRef: React.RefObject<HTMLInputElement>;
    nextInputRef: React.RefObject<HTMLInputElement> | null;
    handleInput: (
        e: React.ChangeEvent<HTMLInputElement>,
        nextInputRef: React.RefObject<HTMLInputElement> | null,
    ) => void;
    handlePaste: (e: React.ClipboardEvent<HTMLInputElement>) => void;
    handleFocus: (index: number, inputRef: React.RefObject<HTMLInputElement>) => void;
    handleBlur: () => void;
    focusIndex: number | null;
    index: number;
    usingMobile: boolean;
};

const SingleNumberInput = ({
    inputRef,
    nextInputRef,
    handleInput,
    handlePaste,
    handleFocus,
    handleBlur,
    focusIndex,
    index,
    usingMobile,
}: props) => {
    return (
        <input
            type="text"
            pattern="[0-9]"
            inputMode="numeric"
            ref={inputRef}
            onClick={() => handleFocus(index, inputRef)}
            onChange={(e) => {
                if (!isNaN(Number(e.target.value))) {
                    if (e.target.value.length > 1) {
                        e.target.value = e.target.value[1];
                    }
                    handleInput(e, nextInputRef);
                } else {
                    if (e.target.value.length > 1) {
                        e.target.value = e.target.value[0];
                    } else {
                        e.target.value = '';
                    }
                }
            }}
            onPaste={(event: React.ClipboardEvent<HTMLInputElement>) => {
                event.preventDefault();
                const pastedText = event.clipboardData.getData('text');
                if (!isNaN(Number(pastedText))) {
                    return handlePaste(event);
                }
            }}
            onFocus={() => handleFocus(index, inputRef)}
            onBlur={handleBlur}
            style={{
                width: usingMobile ? '46px' : '36px',
                height: usingMobile ? '57.5px' : '48px',
                borderRadius: '4px',
                textAlign: 'center',
                fontSize: usingMobile ? '1.5rem' : '1.375rem',
                fontFamily: 'Roboto',
                fontWeight: 'bold',
                lineHeight: '46px',
                border:
                    focusIndex === index
                        ? '2px solid #212529'
                        : inputRef.current?.value
                        ? '1px solid #212529'
                        : '1px solid #CED4DA',
                caretColor: 'transparent',
                transition: 'border-color 0.3s',
            }}
            autoComplete="one-time-code"
            placeholder={focusIndex === index && !inputRef.current?.value ? '_' : ''}
            defaultValue={inputRef.current?.value}
        />
    );
};

export default SingleNumberInput;
