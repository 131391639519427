import { Utility } from '../CustomTypings';
import { fetch_wrapper } from '../utility_logic/fetch_wrapper';

const SET_ELE_UTILS = 'agent/setEleUtils';
const SET_GAS_UTILS = 'agent/setGasUtils';

const setEleUtils = (utilities: Utility[]) => {
    return {
        type: SET_ELE_UTILS,
        payload: utilities,
    };
};

const setGasUtils = (utilities: Utility[]) => {
    return {
        type: SET_GAS_UTILS,
        payload: utilities,
    };
};

export const getRegionElectricUtils = (region: string) => async (dispatch: any) => {
    fetch_wrapper(`/get-region-electric-utilities`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
            region: region,
        }),
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch(setEleUtils(data.utilities));
        })
        .catch((error) => console.log('error', error));
    return;
};

export const getRegionGasUtils = (region: string) => async (dispatch: any) => {
    fetch_wrapper(`/get-region-gas-utilities`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
            region: region,
        }),
    })
        .then((response) => response.json())
        .then((data) => {
            dispatch(setGasUtils(data.utilities));
        })
        .catch((error) => console.log('error', error));
    return;
};

const initialState = { eleUtils: [], gasUtils: [] };

const utilityReducer = (state = initialState, action: any) => {
    let newState;
    switch (action.type) {
        case SET_ELE_UTILS:
            newState = { ...state };
            newState.eleUtils = action.payload;
            return newState;
        case SET_GAS_UTILS:
            newState = { ...state };
            newState.gasUtils = action.payload;
            return newState;
        default:
            return state;
    }
};

export default utilityReducer;
