const minLoadMS = 850;
const maxLoadMS = 1250;

export const force_minimum_load = (suppliedMinLoadMS?: number, suppliedMaxLoadMS?: number) => {
    return new Promise((resolve) =>
        setTimeout(
            resolve,
            Math.floor(
                Math.random() *
                    ((suppliedMaxLoadMS ? suppliedMaxLoadMS : maxLoadMS) -
                        (suppliedMinLoadMS ? suppliedMinLoadMS : minLoadMS) +
                        1),
            ) + (suppliedMinLoadMS ? suppliedMinLoadMS : minLoadMS),
        ),
    );
};
