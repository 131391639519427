const SET_MAX_STEP = 'step/setMaxStep';
const SET_CURRENT_STEP = 'step/setCurrentStep';
const SET_INITIAL_STEPS = 'step/setInitialStep';
const SET_LOGOUT_STEPS = 'step/setLogoutSteps';

const setMaxStepAction = (step: number | null) => {
    return {
        type: SET_MAX_STEP,
        payload: step,
    };
};

const setCurrentStepAction = (step: number | undefined) => {
    return {
        type: SET_CURRENT_STEP,
        payload: step,
    };
};

const setInitialStepsAction = (maxStep: number, currentStep: number) => {
    return {
        type: SET_INITIAL_STEPS,
        payload: [maxStep, currentStep],
    };
};

const setLogOutSteps = () => {
    return {
        type: SET_LOGOUT_STEPS,
    };
};

export const setMaxStep = (step: number | null) => (dispatch: any) => {
    sessionStorage.setItem(
        'kergons-onboarding-progress',
        JSON.stringify({
            ...JSON.parse(sessionStorage.getItem('kergons-onboarding-progress') || '{}'),
            maxStep: step ? step : 0,
        }),
    );
    dispatch(setMaxStepAction(step));
    return;
};

export const setCurrentStep = (step: number | undefined) => (dispatch: any) => {
    sessionStorage.setItem(
        'kergons-onboarding-progress',
        JSON.stringify({
            ...JSON.parse(sessionStorage.getItem('kergons-onboarding-progress') || '{}'),
            currentStep: step ? step : 0,
        }),
    );
    dispatch(setCurrentStepAction(step));
    return;
};

export const setInitialSteps = (maxStep: number, currentStep: number) => (dispatch: any) => {
    sessionStorage.setItem(
        'kergons-onboarding-progress',
        JSON.stringify({
            currentStep: currentStep,
            maxStep: maxStep,
        }),
    );
    dispatch(setInitialStepsAction(maxStep, currentStep));
    return;
};

export const logOutSteps = () => (dispatch: any) => {
    dispatch(setLogOutSteps());
    return;
};

const initialState = { maxStep: 0, currentStep: 0 };

const stepReducer = (state = initialState, action: any) => {
    let newState;
    switch (action.type) {
        case SET_MAX_STEP:
            newState = { ...state };
            if (action.payload === 0) {
                newState.maxStep = 0;
            } else {
                newState.maxStep = action.payload > newState.maxStep ? action.payload : newState.maxStep;
            }
            return newState;
        case SET_CURRENT_STEP:
            newState = { ...state };
            newState.currentStep = action.payload;
            return newState;
        case SET_INITIAL_STEPS:
            newState = { ...initialState };
            newState.maxStep = action.payload[0];
            newState.currentStep = action.payload[1];
            return newState;
        case SET_LOGOUT_STEPS:
            newState = { ...state };
            newState.currentStep = 0;
            newState.maxStep = 0;
            sessionStorage.clear();
            localStorage.clear();
            return newState;
        default:
            return state;
    }
};

export default stepReducer;
