export const extractAddressComponents = (place: any) => {
    const componentForm: { [key: string]: any } = {
        street_number: 'short_name',
        route: 'long_name',
        subpremise: 'short_name', // Added for apartment numbers, etc.
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name',
    };

    let address = {
        line1: '',
        line2: '', // Potentially for subpremise or similar secondary info
        city: '',
        state: '',
        postal_code: '',
        country: '',
    };

    // Go through each component in the address_components array
    for (const component of place.address_components) {
        const addressType = component.types[0];
        if (componentForm[addressType]) {
            const val = component[componentForm[addressType]];

            switch (addressType) {
                case 'street_number':
                    address.line1 = val + ' ' + address.line1; // Ensure street number precedes the route
                    break;
                case 'route':
                    address.line1 += val;
                    break;
                case 'subpremise': // Handling subpremise as part of line 2
                    address.line2 = val; // Prefixing with "Apt" or similar is optional
                    break;
                case 'locality':
                    address.city = val;
                    break;
                case 'administrative_area_level_1':
                    address.state = val;
                    break;
                case 'country':
                    address.country = val;
                    break;
                case 'postal_code':
                    address.postal_code = val;
                    break;
            }
        }
    }

    return address;
};
