import React, { ComponentType, useEffect, useState } from 'react';
import KergonsTitleBox from '../../components/KergonsTitleBox';
import { RootState, useAppDispatch } from '../../store';
import { connect } from 'react-redux';
import OnboardingContentWrapper from '../OnboardingContentWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingBox from '../../components/LoadingBox';
import { setRegion } from '../../store/region';
import { setCurrentStep, setInitialSteps } from '../../store/step';
import { getRegionElectricUtils, getRegionGasUtils } from '../../store/utilities';
import { fetch_wrapper } from '../../utility_logic/fetch_wrapper';
import { force_minimum_load } from '../../utility_logic/minimum_loading';
import { navigate_wrapper } from '../../utility_logic/navigate_wrapper';
import { validateSession } from '../../store/session';
import { getAgentData } from '../../store/titleData';

const onboardingSteps: any = {
    0: '/',
    1: '/signup',
    2: '/planselect',
    3: '/electric',
    4: '/gas',
    5: '/confirm',
    6: '/calculating',
};

const onboardingRoutes: any = {
    '/': 0,
    '/signup': 1,
    '/planselect': 2,
    '/electric': 3,
    '/gas': 4,
    '/confirm': 5,
    '/calculating': 6,
};

const OnboardingPageWrapper = ({
    ComponentPage,
    usingMobile,
}: {
    ComponentPage: ComponentType<any>;
    usingMobile: boolean;
}) => {
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const onboardingProgress = JSON.parse(sessionStorage.getItem('kergons-onboarding-progress')!);
        if (onboardingProgress) {
            if (onboardingRoutes[window.location.pathname] > onboardingProgress.maxStep) {
                dispatch(setCurrentStep(onboardingProgress.maxStep));
            } else {
                dispatch(setCurrentStep(onboardingRoutes[window.location.pathname]));
            }
        }
    }, [window.location.pathname]);

    useEffect(() => {
        const waitPromise = force_minimum_load(2000, 2001);
        dispatch(validateSession())
            .then((result) => {
                // If a valid session
                if (result) {
                    fetch_wrapper(`/get-user-region`, {
                        method: 'GET',
                        credentials: 'include',
                    })
                        .then((response) => {
                            return response.json();
                        })
                        .then((data) => {
                            dispatch(setRegion(data.region));
                            dispatch(getRegionElectricUtils(data.region));
                            dispatch(getRegionGasUtils(data.region));
                        });

                    // Check for onboarding-status object in local storage
                    const onboardingStatus = JSON.parse(localStorage.getItem('kergons-onboarding-status')!);
                    // If onboarding-status is not found
                    if (!onboardingStatus) {
                        // Fetch onboarding-status from API (defaulting to onboarding_status: false)
                        fetch_wrapper(`/get-onboarding-status`, {
                            method: 'GET',
                            credentials: 'include',
                        })
                            .then((response) => {
                                return response.json();
                            })
                            .then((data) => {
                                // Set the onboarding-status object in local storage with returned onboarding_complete value
                                localStorage.setItem(
                                    'kergons-onboarding-status',
                                    JSON.stringify({ onboarding_complete: data.onboarding_complete }),
                                );
                                // If the onboarding_complete is false (not finished)
                                if (!data.onboarding_complete) {
                                    // Get the onboading-progress object in local storage
                                    const onboardingProgress = JSON.parse(
                                        sessionStorage.getItem('kergons-onboarding-progress')!,
                                    );
                                    // If the onboading-progress object is found
                                    if (onboardingProgress) {
                                        dispatch(
                                            setInitialSteps(onboardingProgress.maxStep, onboardingProgress.currentStep),
                                        );

                                        if (onboardingRoutes[window.location.pathname] > onboardingProgress.maxStep) {
                                            // Navigate to the page corresponding to the current onboarding step
                                            navigate_wrapper(
                                                navigate,
                                                location,
                                                onboardingSteps[onboardingProgress.currentStep],
                                            );
                                        }
                                        // If the onboading-progress object is not found
                                    } else {
                                        // Set the onboarding-status object in local storage to the first onboarding step
                                        dispatch(setInitialSteps(1, 1));
                                        // Navigate to the first onboarding step
                                        navigate_wrapper(navigate, location, onboardingSteps[1]);
                                    }
                                }
                                // Do nothing if onboarding complete is true (finished), dont reroute
                            });
                        // If onboarding-status is found
                    } else {
                        // If the onboarding_complete is false (not finished)
                        if (!onboardingStatus.onboarding_complete) {
                            // Get the onboading-progress object in local storage
                            const onboardingProgress = JSON.parse(
                                sessionStorage.getItem('kergons-onboarding-progress')!,
                            );
                            // If the onboading-progress object is found
                            if (onboardingProgress) {
                                dispatch(setInitialSteps(onboardingProgress.maxStep, onboardingProgress.currentStep));
                                // Navigate to the page corresponding to the current onboarding step
                                if (onboardingRoutes[window.location.pathname] > onboardingProgress.maxStep) {
                                    navigate_wrapper(
                                        navigate,
                                        location,
                                        onboardingSteps[onboardingProgress.currentStep],
                                    );
                                }
                                // If the onboading-progress object is not found
                            } else {
                                // Set the onboarding-status object in local storage to the first onboarding step
                                dispatch(setInitialSteps(1, 1));
                                // Navigate to the first onboarding step
                                navigate_wrapper(navigate, location, onboardingSteps[1]);
                            }
                        }
                        // Do nothing if onboarding complete is true (finished), dont reroute
                    }
                    // If not a valid session
                } else {
                    // Navigate to login
                    navigate_wrapper(navigate, location, '/');
                }
            })
            .then(async () => {
                await waitPromise;
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        const searchParams = Object.fromEntries(new URLSearchParams(location.search));
        if (searchParams['agent']) {
            dispatch(getAgentData(searchParams['agent']));
        } else {
            // dispatch(getAgentData(null));
        }
    }, []);

    return (
        <>
            {loading && <LoadingBox usingMobile={usingMobile} inSteps={false}></LoadingBox>}
            <div
                id="PageWrapper"
                style={{
                    display: loading ? 'none' : 'flex',
                    flexDirection: usingMobile ? 'column' : 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: usingMobile ? 'auto' : '100vh',
                    width: '100%',
                    position: usingMobile ? 'relative' : 'unset',
                }}
            >
                <KergonsTitleBox usingMobile={usingMobile}></KergonsTitleBox>
                <OnboardingContentWrapper usingMobile={usingMobile} ComponentPage={ComponentPage} />
            </div>
        </>
    );
};

export default connect((state: RootState) => ({ usingMobile: state.mobile.usingMobile }))(OnboardingPageWrapper);
