export const convert_html_to_svg = (inputRef: HTMLInputElement | null, spanRef: HTMLSpanElement | null) => {
    if (inputRef && spanRef) {
        
        spanRef.textContent = inputRef.value || '';
        
        const computedStyles = window.getComputedStyle(inputRef);
        const fontFamily = computedStyles.fontFamily;
        const fontSize = computedStyles.fontSize;
        const fontWeight = computedStyles.fontWeight;
        const color = computedStyles.color;
        const textValue = inputRef.value;

        const formattedFontFamily = fontFamily.replace(/"/g, '').trim().split(' ').join('+');

        const fontUrl = `https://fonts.googleapis.com/css2?family=${formattedFontFamily}`;

        // Create SVG content
        const svgContent = `
            <svg xmlns="http://www.w3.org/2000/svg" width="${spanRef.offsetWidth + 20}" height="${spanRef.offsetHeight + 20}">
                <style>
                @import url(${fontUrl});
                text {
                    font-family: ${fontFamily};
                    font-size: ${fontSize};
                    font-weight: ${fontWeight};
                }
                </style>
                <text x="0" y="20" fill="${color}">${textValue}</text>
            </svg>
        `;

        // Convert SVG to Base64
        const base64Svg = `data:image/svg+xml;base64,${btoa(svgContent)}`;

        return base64Svg;
    }
};
