import { MuiTelInput } from 'mui-tel-input';
import { useEffect, useState } from 'react';
import { CountryCode } from 'libphonenumber-js';

type props = {
    phoneNumber: string;
    setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
    validPhoneNumber: boolean | null;
};

const PhoneNumberInput = ({ phoneNumber, setPhoneNumber, validPhoneNumber }: props) => {
    const [defaultCountry, setDefaultCountry] = useState<CountryCode>('US' as CountryCode);

    useEffect(() => {
        setDefaultCountry(
            window.location.hostname.endsWith('secure.wattsons.ca') ? ('CA' as CountryCode) : ('US' as CountryCode),
        );
    }, []);

    return (
        <MuiTelInput
            onlyCountries={['US', 'CA']}
            defaultCountry={defaultCountry}
            forceCallingCode
            autoComplete="off"
            value={phoneNumber}
            onChange={(v) => setPhoneNumber(v)}
            className="MuiTelInput-width-large"
            sx={{
                '& .MuiOutlinedInput-root': {
                    paddingLeft: '5px',
                    '& .MuiInputBase-input': {
                        fontSize: '1rem',
                        padding: '12px',
                        fontWeight: '500',
                        paddingBottom: '11px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: '4px',
                        borderColor: phoneNumber.replace(/\s/g, '').length === 12 ? '#6C757D' : '#CED4DA',
                        transition: 'border-color 0.3s',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#6C757D',
                        borderWidth: '1px',
                    },
                },
                '& .MuiTypography-root': {
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    color: '#343A40',
                },
            }}
        />
    );
};

export default PhoneNumberInput;
