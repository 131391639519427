import { useEffect, useState } from 'react';
import LoadingSpinner from '../LoadingSpinnerContainer/loadingSpinner';

const LoadingBox = ({ usingMobile, inSteps }: { usingMobile: boolean; inSteps: boolean }) => {
    const [periodCounter, setPeriodCounter] = useState<number>(0);

    useEffect(() => {
        const interval1 = setInterval(() => {
            setPeriodCounter((v) => (v === 3 ? 0 : v + 1));
        }, 300);

        return () => {
            clearInterval(interval1);
        };
    }, []);

    return (
        <div
            style={{
                height: '100vh',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: inSteps ? '12.5vh' : '',
                flexDirection: 'column',
            }}
        >
            <LoadingSpinner active={true} usingMobile={usingMobile} large={true}></LoadingSpinner>
            <div
                style={{
                    fontFamily: 'Raleway',
                    fontSize: '1.5rem',
                    fontWeight: '600',
                    letterSpacing: '2.5',
                    textAlign: 'center',
                    marginTop: '32px',
                }}
            >
                <span style={{ visibility: 'hidden' }}>.</span>
                <span style={{ visibility: 'hidden' }}>.</span>
                <span style={{ visibility: 'hidden' }}>.</span>
                loading
                <span style={{ visibility: periodCounter > 0 ? 'visible' : 'hidden' }}>.</span>
                <span style={{ visibility: periodCounter > 1 ? 'visible' : 'hidden' }}>.</span>
                <span style={{ visibility: periodCounter > 2 ? 'visible' : 'hidden' }}>.</span>
            </div>
        </div>
    );
};

export default LoadingBox;
