const SET_REGION = 'region/setRegion';

const setRegionAction = (region: string) => {
    return {
        type: SET_REGION,
        payload: region,
    };
};

export const setRegion = (region: string) => (dispatch: any) => {
    dispatch(setRegionAction(region));
    return;
};

const initialState = null;

const regionReducer = (state = initialState, action: any) => {
    let newState;
    switch (action.type) {
        case SET_REGION:
            newState = action.payload;
            return newState;
        default:
            return state;
    }
};

export default regionReducer;
