import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { fetch_wrapper } from '../../utility_logic/fetch_wrapper';
import LoadingSpinner from '../../components/LoadingSpinnerContainer/loadingSpinner';
import { Utility } from '../../CustomTypings';
import SignatureForm from '../../components/SignatureForm';
import ProviderForm from '../../components/ProviderForm';
import PlanSelector from '../../components/PlanSelector';
import { force_minimum_load } from '../../utility_logic/minimum_loading';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import SignaturePad from 'react-signature-pad-wrapper';
import { convert_html_to_svg } from '../../utility_logic/convert_html_to_svg';
import { extractAddressComponents } from '../../utility_logic/extract_address_components';
import clsx from 'clsx';

const CustomerInfoForm = ({
    validSession,
    usingMobile,
    customerName,
    setPageIndex,
    setPlanProductObj,
    setCustomerId,
    stripePlanProducts,
    extracted_address_components,
}: {
    validSession: boolean;
    usingMobile: boolean;
    customerName: string | null;
    setPageIndex: React.Dispatch<React.SetStateAction<number>>;
    setPlanProductObj: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
    setCustomerId: React.Dispatch<React.SetStateAction<string>>;
    stripePlanProducts: {
        [key: string]: any;
    }[];
    extracted_address_components:
        | {
              [key: string]: any;
          }
        | undefined;
}) => {
    const [electricProvider, setElectricProvider] = useState<Utility | null>(null);
    const [gasProvider, setGasProvider] = useState<Utility | null>(null);

    const [updateSent, setUpdateSent] = useState<boolean>(false);

    const [isPaymentMethodElementComplete, setIsPaymentMethodElementComplete] = useState(false);

    const canvasRef = useRef<SignaturePad | null>(null);
    const signatureTextRef = useRef<HTMLInputElement | null>(null);
    const spanRef = useRef<HTMLSpanElement>(null);
    const [drawing, setDrawing] = useState<boolean>(true);

    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (
                event.key === 'Enter' &&
                !updateSent &&
                isPaymentMethodElementComplete &&
                electricProvider &&
                gasProvider
            ) {
                event.preventDefault();
                event.stopPropagation();
                customerFormSubmit();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [updateSent, isPaymentMethodElementComplete, electricProvider, gasProvider]);

    const customerFormSubmit = async () => {
        const waitPromise = force_minimum_load(2000, 2500);
        setUpdateSent(true);

        let svgData = null;

        if (drawing) {
            svgData = canvasRef.current?.toDataURL('image/svg+xml');
        } else {
            svgData = convert_html_to_svg(signatureTextRef.current, spanRef.current);
        }

        if (svgData) {
            fetch_wrapper(`/upload-signature-svg-data`, {
                method: 'POST',
                body: JSON.stringify({
                    signature_svg_data: svgData,
                }),
                credentials: 'include',
            });

            if (stripe && elements) {
                const paymentElement = elements.getElement(PaymentElement);

                if (paymentElement) {
                    elements.submit();

                    const { setupIntent, error } = await stripe.confirmSetup({
                        elements,
                        redirect: 'if_required',
                        confirmParams: {
                            return_url: `${window.location.protocol}//${window.location.host}/electric`,
                        },
                    });

                    if (!error || (error.setup_intent && error.setup_intent.status === 'succeeded')) {
                        fetch_wrapper(`/stripe-create-customer`, {
                            method: 'POST',
                            body: JSON.stringify({
                                address: extracted_address_components,
                            }),
                            credentials: 'include',
                        })
                            .then((response) => {
                                if (!response.ok) {
                                    throw Error(`HTTP error, status = ${response.status}`);
                                }
                                return response.json();
                            })
                            .then(async (data) => {
                                setCustomerId(data.customer_id);
                                fetch_wrapper(`/stripe-payment-method-setup`, {
                                    method: 'POST',
                                    body: JSON.stringify({
                                        customer_id: data.customer_id,
                                        payment_method_id:
                                            error?.setup_intent?.status === 'succeeded'
                                                ? error?.setup_intent?.payment_method
                                                : setupIntent?.payment_method,
                                    }),
                                    credentials: 'include',
                                })
                                    .then((response) => {
                                        if (!response.ok) {
                                            throw new Error(`HTTP error, status = ${response.status}`);
                                        }
                                        return response.json();
                                    })
                                    .then(async (data) => {
                                        sessionStorage.setItem(
                                            'kergons-onboarding-form-information',
                                            JSON.stringify({
                                                ...JSON.parse(
                                                    sessionStorage.getItem('kergons-onboarding-form-information') ||
                                                        '{}',
                                                ),
                                                electric_provider: electricProvider,
                                                gas_provider: gasProvider,
                                            }),
                                        );
                                        await waitPromise;
                                        setPageIndex(1);
                                    })
                                    .catch((error) => {
                                        setUpdateSent(false);
                                        console.log('error', error);
                                    });
                            })
                            .catch((error) => {
                                setUpdateSent(false);
                                console.log('error', error);
                            });
                    } else {
                        setUpdateSent(false);
                    }
                }
            }
        }
    };

    return (
        <>
            {validSession && (
                <>
                    <form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: usingMobile ? 'space-between' : 'flex-start',
                            alignItems: 'flex-start',
                            width: '100%',
                            marginTop: usingMobile ? '6vh' : '5vh',
                            marginBottom: '0px',
                        }}
                        autoComplete="off"
                    >
                        <label
                            style={{
                                fontFamily: 'Raleway',
                                fontWeight: '600',
                                fontSize: '1.25rem',
                                lineHeight: '36px',
                            }}
                        >
                            Plan Selection
                        </label>

                        <div style={{ height: '3vh' }}></div>

                        <PlanSelector
                            setPlanProductObj={setPlanProductObj}
                            stripePlanProducts={stripePlanProducts}
                        ></PlanSelector>

                        <div style={{ height: '2vh' }}></div>

                        <label
                            style={{
                                fontFamily: 'Raleway',
                                fontWeight: '600',
                                fontSize: '1.25rem',
                                lineHeight: '36px',
                            }}
                        >
                            Provider Information
                        </label>

                        <div style={{ height: '3vh' }}></div>

                        <ProviderForm
                            setElectricProvider={setElectricProvider}
                            electricProvider={electricProvider}
                            setGasProvider={setGasProvider}
                            gasProvider={gasProvider}
                        ></ProviderForm>

                        <div style={{ height: '5vh' }}></div>

                        <SignatureForm
                            customerName={customerName!}
                            canvasRef={canvasRef}
                            drawing={drawing}
                            setDrawing={setDrawing}
                            signatureTextRef={signatureTextRef}
                            spanRef={spanRef}
                        ></SignatureForm>

                        <div style={{ height: '5vh' }}></div>

                        <label
                            style={{
                                fontFamily: 'Raleway',
                                fontWeight: '600',
                                fontSize: '1.25rem',
                                lineHeight: '36px',
                            }}
                        >
                            Billing Information
                        </label>

                        <div style={{ height: '3vh' }}></div>

                        <div style={{ width: '100%' }}>
                            <PaymentElement onChange={(e) => setIsPaymentMethodElementComplete(e.complete)} />
                        </div>

                        <div style={{ height: '4vh' }}></div>

                        <button
                            type="submit"
                            className={clsx('button submit', {
                                active: electricProvider && gasProvider && isPaymentMethodElementComplete,
                                loading: updateSent,
                                'mobile mobile-full': usingMobile,
                            })}
                            disabled={
                                !electricProvider || !gasProvider || !isPaymentMethodElementComplete || updateSent
                            }
                            onClick={() => customerFormSubmit()}
                        >
                            {updateSent ? '' : 'NEXT'}

                            <LoadingSpinner
                                active={updateSent}
                                usingMobile={usingMobile}
                                large={false}
                            ></LoadingSpinner>
                        </button>
                    </form>
                </>
            )}
            {!validSession && <div>Please Log In Before Adding User Data</div>}
        </>
    );
};

export default connect((state: RootState) => ({
    validSession: state.session.validSession,
    usingMobile: state.mobile.usingMobile,
    customerName: state.userFormData.name,
    place: state.userFormData.place,
}))(CustomerInfoForm);
