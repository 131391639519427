import { TextField } from '@mui/material';
import React, { FormEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetch_wrapper } from '../../utility_logic/fetch_wrapper';
import * as stepActions from '../../store/step';
import * as sessionActions from '../../store/session';
import { navigate_wrapper } from '../../utility_logic/navigate_wrapper';
import clsx from 'clsx';

const CalculatingPage = ({ validSession, usingMobile }: { validSession: boolean; usingMobile: boolean }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const handleLogout = () => {
        dispatch(stepActions.logOutSteps());
        fetch_wrapper(`/log-out-session`, {
            method: 'GET',
            credentials: 'include',
        });
        dispatch(sessionActions.sessionInvalidated());
        navigate_wrapper(navigate, location, '/');
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                handleLogout();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <>
            {validSession && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: usingMobile ? '25vh' : '',
                        }}
                    >
                        <div
                            style={{
                                fontFamily: 'Raleway',
                                fontWeight: '600',
                                fontSize: usingMobile ? '1.75rem' : '1.875rem',
                                lineHeight: usingMobile ? '36px' : '40px',
                                height: usingMobile ? '4vh' : '',
                            }}
                        >
                            WORKING ON IT...
                        </div>
                        <div style={{ height: usingMobile ? '3vh' : '2vh' }}></div>
                        <div
                            style={{
                                height: usingMobile ? '18vh' : '',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        fontFamily: 'AmericanTypewriter',
                                        fontWeight: '400',
                                        fontSize: '1.125rem',
                                        lineHeight: '26px',
                                    }}
                                >
                                    Thank you for singing up.
                                </div>
                                {!usingMobile && <div style={{ height: '1vh' }}></div>}
                                <div
                                    style={{
                                        fontFamily: 'AmericanTypewriter',
                                        fontWeight: '400',
                                        fontSize: '1.125rem',
                                        lineHeight: '26px',
                                    }}
                                >
                                    As soon as we finish your analysis we’ll send you a text.
                                </div>
                            </div>
                            {usingMobile && (
                                <div
                                    style={{
                                        fontFamily: 'AmericanTypewriter',
                                        fontWeight: '400',
                                        fontSize: '1.125rem',
                                        lineHeight: '26px',
                                    }}
                                >
                                    Click on the Kergons logo below to add our contact information to your phone.
                                </div>
                            )}
                        </div>
                    </div>

                    {usingMobile && (
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '50vh',
                            }}
                        >
                            <a href="/The-Kergons-Company.vcf" download="The-Kergons-Company.vcf">
                                <div
                                    style={{
                                        border: '2px solid #000000',
                                        borderRadius: '5px',
                                        padding: '20px 32px 20px 32px',
                                        boxShadow: '0 0 9px #00000085',
                                    }}
                                >
                                    <img
                                        src="/kergons_logo_black.svg"
                                        alt=""
                                        width={'160px'}
                                        style={{ cursor: 'pointer' }}
                                    ></img>
                                </div>
                            </a>
                        </div>
                    )}

                    {!usingMobile && <div style={{ height: '4vh' }}></div>}

                    <div
                        style={{
                            display: usingMobile ? 'flex' : '',
                            width: '100%',
                            justifyContent: 'center',
                        }}
                    >
                        <button
                            className={clsx('button submit', {
                                active: true,
                                'mobile mobile-full': usingMobile,
                            })}
                            onClick={handleLogout}
                        >
                            HOME
                        </button>
                    </div>
                </>
            )}
            {!validSession && <div>Please Log In Before Adding User Data</div>}
        </>
    );
};

export default connect((state: RootState) => ({
    validSession: state.session.validSession,
    usingMobile: state.mobile.usingMobile,
}))(CalculatingPage);
