import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, useAppDispatch } from '../../store';
import { fetch_wrapper } from '../../utility_logic/fetch_wrapper';
import { force_minimum_load } from '../../utility_logic/minimum_loading';
import LoadingSpinner from '../../components/LoadingSpinnerContainer/loadingSpinner';
import { useLocation, useNavigate } from 'react-router-dom';
import SocketService from '../../utility_logic/socketService';
import { navigate_wrapper } from '../../utility_logic/navigate_wrapper';
import { setCurrentStep, setMaxStep } from '../../store/step';
import clsx from 'clsx';

const PaymentConfirmationForm = ({
    validSession,
    usingMobile,
    planProductObj,
    customerId,
}: {
    validSession: boolean;
    usingMobile: boolean;
    planProductObj: { [key: string]: any };
    customerId: string;
}) => {
    const [updateSent, setUpdateSent] = useState<boolean>(false);
    const [stripeSuccess, setStripeSuccess] = useState<boolean>(false);

    const [promoCode, setPromoCode] = useState<string>('');
    const [promoCodeFocused, setPromoCodeFocused] = useState(false);
    const [promoCodeObj, setPromoCodeObj] = useState<{ [key: string]: any } | null>(null);
    const [validPromoCode, setValidPromoCode] = useState<boolean | null>(null);
    const [fetchingPromoCode, setFetchingPromoCode] = useState<boolean>(false);

    const [waitPromise, setWaitPromise] = useState<Promise<unknown> | null>(null);

    // const paymentIntentId = useRef<string>('');
    const socketService = useRef<SocketService | null>(null);

    const [consent, setConsent] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        return () => {
            if (socketService.current) {
                socketService.current.disconnect();
            }
        };
    }, []);

    const socketSetup = (customerId: string) => {
        if (customerId) {
            socketService.current = SocketService.getInstance();
            socketService.current.connect(customerId, window.location.hostname);

            socketService.current.on('customer-subscription-created', async (data) => {
                // if (data.id === paymentIntentId.current) {
                await waitPromise;
                setStripeSuccess(true);
                // }
            });

            socketService.current.on('stripe-payment-intent-failed', (data) => {
                setStripeSuccess(false);
            });

            socketService.current.on('stripe-invoice-action-required', (data) => {
                setStripeSuccess(false);
            });
        }
    };

    const getPromoCodeData = () => {
        const waitPromise = force_minimum_load();
        setFetchingPromoCode(true);
        setValidPromoCode(null);
        fetch_wrapper(`/get-stripe-promo-code`, {
            method: 'POST',
            body: JSON.stringify({
                promo_code: promoCode,
            }),
            credentials: 'include',
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error, status = ${response.status}`);
                }
                return response.json();
            })
            .then(async (data) => {
                console.log(data);
                if (data.promotional_code_data) {
                    await waitPromise;
                    setPromoCodeObj(data.promotional_code_data);
                    setValidPromoCode(true);
                } else {
                    await waitPromise;
                    setValidPromoCode(false);
                    setPromoCode('');
                }
                setFetchingPromoCode(false);
            })
            .catch(async (error) => {
                await waitPromise;
                setFetchingPromoCode(false);
                setValidPromoCode(false);
                setPromoCode('');
                console.log('error', error);
            });
    };

    const stripeSubscriptionSubmit = async () => {
        setWaitPromise(force_minimum_load(2000, 2500));
        socketSetup(customerId);
        setUpdateSent(true);

        fetch_wrapper(`/stripe-subscription-setup`, {
            method: 'POST',
            body: JSON.stringify({
                customer_id: customerId,
                price_id: planProductObj.price.id,
                promoCodeObj: promoCodeObj,
            }),
            credentials: 'include',
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error, status = ${response.status}`);
                }
                return response.json();
            })
            .then(async (data) => {
                // paymentIntentId.current = data.payment_intent_id;
                if (data.duplicate_subscription) {
                    await waitPromise;
                    setStripeSuccess(true);
                }
            })
            .catch((error) => {
                setUpdateSent(false);
                console.log('error', error);
            });
    };

    useEffect(() => {
        const f = async () => {
            if (stripeSuccess) {
                await force_minimum_load(1700, 1700);
                navigate_wrapper(navigate, location, '/electric');
                dispatch(setMaxStep(3));
                dispatch(setCurrentStep(3));
            }
        };
        f();
    }, [stripeSuccess]);

    return (
        <>
            {validSession && (
                <>
                    <form
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: usingMobile ? 'space-between' : 'flex-start',
                            alignItems: usingMobile ? 'center' : 'flex-start',
                            width: '100%',
                            height: usingMobile ? '74vh' : '',
                            marginTop: usingMobile ? '6vh' : '5vh',
                            marginBottom: '0px',
                        }}
                        autoComplete="off"
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                width: '100%',
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    color: '#212529',
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: 'Raleway',
                                        fontWeight: '600',
                                        fontSize: '1.4rem',
                                        height: usingMobile ? '3vh' : '',
                                    }}
                                >
                                    {' '}
                                    Summary
                                </div>
                                <div style={{ height: '3vh' }}></div>
                                <div style={{ width: '100%', border: '1px solid #CED4DA', opacity: '0.5' }}></div>
                                <div style={{ height: '3vh' }}></div>
                                {planProductObj && (
                                    <>
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: usingMobile ? 'flex-start' : 'center',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    maxWidth: '40%',
                                                    fontFamily: 'Raleway',
                                                    fontWeight: '400',
                                                    fontSize: '1rem',
                                                    whiteSpace: 'pre',
                                                    height: usingMobile ? '2vh' : '',
                                                }}
                                            >
                                                Plan: <span style={{ fontWeight: '600' }}>{planProductObj.name}</span>
                                            </div>
                                            <div
                                                style={{
                                                    maxWidth: '55%',
                                                    display: 'flex',
                                                    flexGrow: 1,
                                                    justifyContent: 'flex-end',
                                                    alignItems: usingMobile ? 'flex-end' : 'center',
                                                    fontFamily: 'Raleway',
                                                    fontWeight: '400',
                                                    fontSize: '1rem',
                                                    whiteSpace: 'pre',
                                                    flexDirection: usingMobile ? 'column' : 'unset',
                                                }}
                                            >
                                                {!usingMobile &&
                                                    promoCodeObj &&
                                                    promoCodeObj.coupon.metadata.applies_to === planProductObj.id && (
                                                        <>
                                                            <div
                                                                style={{
                                                                    fontStyle: 'italic',
                                                                }}
                                                            >
                                                                {promoCode} - $
                                                                {(
                                                                    +planProductObj.price.unit_amount * 0.01 -
                                                                    +promoCodeObj.coupon.amount_off * 0.01
                                                                ).toFixed(2)}
                                                            </div>
                                                            <div style={{ width: '7.5%' }}></div>
                                                        </>
                                                    )}
                                                <div
                                                    style={{
                                                        textDecoration: promoCodeObj ? 'line-through' : 'unset',
                                                        textDecorationThickness: '0.2rem',
                                                        textDecorationColor: '#CED4DA',
                                                        color: promoCodeObj ? '#CED4DA' : 'default',
                                                    }}
                                                >
                                                    ${(+planProductObj.price.unit_amount * 0.01).toFixed(2)}
                                                </div>

                                                {usingMobile &&
                                                    promoCodeObj &&
                                                    promoCodeObj.coupon.metadata.applies_to === planProductObj.id && (
                                                        <>
                                                            <div style={{ height: '2.5vh' }}></div>
                                                            <div
                                                                style={{
                                                                    fontStyle: 'italic',
                                                                }}
                                                            >
                                                                {promoCode} - $
                                                                {(
                                                                    +planProductObj.price.unit_amount * 0.01 -
                                                                    +promoCodeObj.coupon.amount_off * 0.01
                                                                ).toFixed(2)}
                                                            </div>
                                                        </>
                                                    )}
                                            </div>
                                        </div>
                                        <div style={{ height: '3vh' }}></div>
                                        <div
                                            style={{ width: '100%', border: '1px solid #CED4DA', opacity: '0.5' }}
                                        ></div>
                                        <div style={{ height: '3vh' }}></div>
                                        <div
                                            style={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                fontFamily: 'Raleway',
                                                fontWeight: '400',
                                                fontSize: '1rem',
                                            }}
                                        >
                                            <div>Total:</div>
                                            <div style={{ fontWeight: '600', whiteSpace: 'pre' }}>
                                                $
                                                {promoCodeObj
                                                    ? (
                                                          +planProductObj.price.unit_amount * 0.01 -
                                                          +promoCodeObj.coupon.amount_off * 0.01
                                                      ).toFixed(2)
                                                    : (+planProductObj.price.unit_amount * 0.01).toFixed(2)}
                                                {planProductObj.price.type === 'recurring'
                                                    ? '   /   ' +
                                                      planProductObj.price.recurring.interval.charAt(0).toUpperCase() +
                                                      planProductObj.price.recurring.interval.slice(1)
                                                    : ''}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>

                            <div style={{ height: '5vh' }}></div>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        placeholder={validPromoCode ? 'Promo Code Accepted' : 'Promo Code'}
                                        className="basic_input"
                                        value={validPromoCode ? '' : promoCode}
                                        onChange={(e) => setPromoCode(e.target.value)}
                                        onFocus={() => setPromoCodeFocused(true)}
                                        onBlur={() => setPromoCodeFocused(false)}
                                        style={{
                                            height: '4vh',
                                            maxHeight: '46px',
                                            width: usingMobile ? '45%' : '30%',
                                            border: '1px solid',
                                            borderRadius: '4px',
                                            paddingLeft: '8px',
                                            fontSize: '0.8rem',
                                            lineHeight: '22px',
                                            fontFamily: 'Roboto',
                                            fontWeight: '400',
                                            borderColor: '#CED4DA',
                                            color: '#212529',
                                            transition: 'border-color 0.3s',
                                            pointerEvents: validPromoCode ? 'none' : 'unset',
                                        }}
                                    />
                                    <div style={{ width: '5%' }}></div>
                                    <button
                                        className={clsx('button submit mini', {
                                            active: promoCode,
                                            loading: fetchingPromoCode || validPromoCode,
                                            'mobile-mini': usingMobile,
                                        })}
                                        disabled={validPromoCode || !promoCode || fetchingPromoCode}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            getPromoCodeData();
                                        }}
                                    >
                                        {validPromoCode ? (
                                            <svg
                                                width="35"
                                                height="35"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M5 12l5 5L20 7"
                                                    stroke="#212529"
                                                    strokeWidth="2"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="checkmark-path"
                                                />
                                            </svg>
                                        ) : fetchingPromoCode ? (
                                            ''
                                        ) : (
                                            'APPLY'
                                        )}
                                        <LoadingSpinner
                                            active={fetchingPromoCode}
                                            usingMobile={usingMobile}
                                            large={false}
                                        ></LoadingSpinner>
                                    </button>
                                </div>
                            </div>

                            <div style={{ height: usingMobile ? '4vh' : '7vh' }}></div>

                            <div
                                style={{
                                    display: 'flex',
                                    // width: usingMobile ? '100%' : '50%',
                                    cursor: 'pointer',
                                    alignItems: 'center',
                                    height: usingMobile ? '3vh' : '27px',
                                    position: 'relative',
                                }}
                                onClick={() => setConsent(!consent)}
                            >
                                <input type="checkbox" checked={consent} readOnly={true} />
                                <label
                                    style={{
                                        fontFamily: 'Raleway',
                                        fontSize: '0.875rem',
                                        lineHeight: '22px',
                                        color: '#343A40',
                                        marginLeft: '37px',
                                        userSelect: 'none',
                                    }}
                                >
                                    I agree to{' '}
                                    <span>
                                        <span
                                            style={{
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                window.open('https://wattsons.io/terms-of-services', '_blank');
                                            }}
                                        >
                                            terms and conditions
                                        </span>
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div style={{ height: '5vh' }}></div>

                        <button
                            type="submit"
                            className={clsx('button submit', {
                                active: consent,
                                loading: updateSent,
                                success: stripeSuccess,
                                'mobile mobile-full': usingMobile,
                            })}
                            disabled={updateSent || stripeSuccess || !consent}
                            onClick={(e) => {
                                e.preventDefault();
                                stripeSubscriptionSubmit();
                            }}
                        >
                            {stripeSuccess ? (
                                <svg
                                    width="50"
                                    height="50"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M5 12l5 5L20 7"
                                        stroke="#212529"
                                        strokeWidth="2"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="checkmark-path"
                                    />
                                </svg>
                            ) : updateSent ? (
                                ''
                            ) : (
                                'SUBMIT'
                            )}

                            <LoadingSpinner
                                active={updateSent && !stripeSuccess}
                                usingMobile={usingMobile}
                                large={false}
                            ></LoadingSpinner>
                        </button>
                    </form>
                </>
            )}
            {!validSession && <div>Please Log In Before Adding User Data</div>}
        </>
    );
};

export default connect((state: RootState) => ({
    validSession: state.session.validSession,
    usingMobile: state.mobile.usingMobile,
}))(PaymentConfirmationForm);
