import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { Utility } from '../../CustomTypings';

const InfoForm = ({
    usingMobile,
    eleUtils,
    gasUtils,
    setElectricProvider,
    electricProvider,
    setGasProvider,
    gasProvider,
}: {
    usingMobile: boolean;
    eleUtils: Utility[];
    gasUtils: Utility[];
    setElectricProvider: React.Dispatch<React.SetStateAction<Utility | null>>;
    setGasProvider: React.Dispatch<React.SetStateAction<Utility | null>>;
    electricProvider: Utility | null;
    gasProvider: Utility | null;
}) => {
    const [nameFocused, setNameFocused] = useState<boolean>(false);
    const [addressFocused, setAddressFocused] = useState<boolean>(false);

    return (
        <>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        height: usingMobile ? '6vh' : '',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            fontFamily: 'Raleway',
                            fontSize: '0.875rem',
                            fontWeight: '500',
                            color: '#495057',
                        }}
                    >
                        Electricity Provider
                    </div>

                    <select
                        style={{
                            fontFamily: 'Roboto',
                            fontWeight: '400',
                            fontSize: usingMobile ? '0.875rem' : '1rem',
                            color: '#212529',
                            borderRadius: '5px',
                            borderColor: electricProvider ? '#6C757D' : '#ced4da',
                            height: '48px',
                            padding: '12px',
                            appearance: 'none',
                            backgroundImage: `url(/caret-down.svg)`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'calc(100% - 10px) center',
                            backgroundSize: '24px',
                            paddingRight: '40px',
                            paddingLeft: '12px',
                            width: '50%',
                        }}
                        value={electricProvider ? JSON.stringify(electricProvider) : undefined}
                        onChange={(e) => setElectricProvider(JSON.parse(e.target.value))}
                    >
                        <option value="" hidden>
                            -Select-
                        </option>
                        {eleUtils &&
                            eleUtils.map((value: Utility) => {
                                return (
                                    <option
                                        style={{
                                            fontFamily: 'Roboto',
                                            fontWeight: '400',
                                            fontSize: '1rem',
                                        }}
                                        value={JSON.stringify(value)}
                                        key={value.full_name}
                                    >
                                        {value.full_name}
                                    </option>
                                );
                            })}
                    </select>
                </div>

                <div style={{ height: '3vh' }}></div>

                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        height: usingMobile ? '6vh' : '',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            fontFamily: 'Raleway',
                            fontSize: '0.875rem',
                            fontWeight: '500',
                            color: '#495057',
                        }}
                    >
                        Gas Provider
                    </div>

                    <select
                        style={{
                            fontFamily: 'Roboto',
                            fontWeight: '400',
                            fontSize: usingMobile ? '0.875rem' : '1rem',
                            color: '#212529',
                            borderRadius: '5px',
                            borderColor: gasProvider ? '#6C757D' : '#ced4da',
                            height: '48px',
                            padding: '12px',
                            appearance: 'none',
                            backgroundImage: `url(/caret-down.svg)`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'calc(100% - 10px) center',
                            backgroundSize: '24px',
                            paddingRight: '40px',
                            paddingLeft: '12px',
                            width: '50%',
                        }}
                        value={gasProvider ? JSON.stringify(gasProvider) : undefined}
                        onChange={(e) => setGasProvider(JSON.parse(e.target.value))}
                    >
                        <option value="" hidden>
                            -Select-
                        </option>
                        {gasUtils &&
                            gasUtils.map((value: Utility) => {
                                return (
                                    <option
                                        style={{
                                            fontFamily: 'Roboto',
                                            fontWeight: '400',
                                            fontSize: '1rem',
                                        }}
                                        value={JSON.stringify(value)}
                                        key={value.full_name}
                                    >
                                        {value.full_name}
                                    </option>
                                );
                            })}
                    </select>
                </div>
            </div>
        </>
    );
};

export default connect((state: RootState) => ({
    usingMobile: state.mobile.usingMobile,
    eleUtils: state.utilities.eleUtils,
    gasUtils: state.utilities.gasUtils,
}))(InfoForm);
