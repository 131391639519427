import styled, { keyframes } from 'styled-components';

const createDashAnimation = ($primary: string, $index: number, $dasharrayWidth: number) => keyframes`
0% {
stroke-dashoffset: ${($primary === 'true' ? 0 : 95) + $index * $dasharrayWidth};
}
100% {
    stroke-dashoffset: ${($primary === 'true' ? -190 : -95) + $index * $dasharrayWidth};
}
`;

const AnimatedPath = styled.path<{
    $dasharrayWidth: number;
    $primary: string;
    $index: number;
    $animationSpeed: string;
}>`
    stroke-dasharray: ${({ $dasharrayWidth }) => $dasharrayWidth} ${({ $dasharrayWidth }) => 190 - $dasharrayWidth};
    animation: ${({ $primary, $index, $dasharrayWidth }) => createDashAnimation($primary, $index, $dasharrayWidth)}
        ${({ $animationSpeed }) => $animationSpeed} reverse linear infinite;
`;

const LoadingSpinnerPath = ({
    primary,
    dasharrayWidth,
    index,
    pathLength,
    animationSpeed,
    opacityMultiplier,
    large,
}: {
    primary: boolean;
    dasharrayWidth: number;
    index: number;
    pathLength: number;
    animationSpeed: string;
    opacityMultiplier: number;
    large: boolean;
}) => {
    return (
        <AnimatedPath
            id="spinner-path"
            d="M1.33863 31.8049C5.42877 32.4602 12.2676 34.1568 18.5859 40.1807C23.0294 44.4168 28.7946 47 35.5125 47C42.3461 47 47.6511 44.7286 52.6732 39.8799C54.728 37.8959 61.031 32.8339 69.3472 31.9404C70.8269 31.7815 70.9859 31.3715 70.9859 30.0866C70.9859 29.5242 70.9991 28.0343 70.9997 27.2995C71.0003 18.7911 70.9997 19.5965 70.9997 17.2545C70.9997 15.844 70.8389 15.2582 68.5757 14.9084C60.3131 13.6311 54.5949 8.92248 52.4461 6.81816C48.1799 2.63931 42.3099 0 35.5011 0C28.8265 0 23.2546 2.53535 18.832 6.73815C16.5623 8.89562 14.0852 10.6092 11.1607 12.1003C8.14484 13.6381 2.14153 15.1998 1.50732 15.2676C0.401681 15.385 0.0210096 15.7687 0.0210096 16.6494L0.00348364 30.3541C0.00348364 30.5562 -0.135272 31.5689 1.33863 31.8049Z"
            stroke="url(#spinner-gradient)"
            strokeWidth={large ? '4' : '6'}
            strokeLinecap="round"
            fill="none"
            strokeOpacity={index / (large ? pathLength : pathLength * opacityMultiplier)}
            // strokeOpacity={index / (pathLength * opacityMultiplier)}
            $dasharrayWidth={dasharrayWidth}
            $primary={primary.toString()}
            $index={index}
            $animationSpeed={animationSpeed}
        />
    );
};

export default LoadingSpinnerPath;
