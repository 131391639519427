import { fetch_wrapper } from '../utility_logic/fetch_wrapper';

const SET_USING_STRIPE = 'stripe/setUsingStripe';
const SET_STRIPE_SUCCESS = 'stripe/setStripeSuccess';

const setUsingStripeAction = (using: boolean) => {
    return {
        type: SET_USING_STRIPE,
        payload: using,
    };
};

const setStripeSuccessAction = (stripeSuccess: boolean) => {
    return {
        type: SET_STRIPE_SUCCESS,
        payload: stripeSuccess,
    };
};

export const setUsingStripe = () => async (dispatch: any) => {
    const response = await fetch_wrapper(`/using-stripe-check`, {
        method: 'GET',
        credentials: 'include',
    });
    const data = await response.json();
    dispatch(setUsingStripeAction(data.use_stripe));
    return data.use_stripe;
};

export const setStripeSuccess = (stripeSuccess: boolean) => (dispatch: any) => {
    dispatch(setStripeSuccessAction(stripeSuccess));
    return;
};

const initialState = { usingStripe: false, stripeSuccess: false };

const stripeReducer = (state = initialState, action: any) => {
    let newState;
    switch (action.type) {
        case SET_USING_STRIPE:
            newState = { ...state };
            newState.usingStripe = action.payload;
            return newState;
        case SET_STRIPE_SUCCESS:
            newState = { ...state };
            newState.stripeSuccess = action.payload;
            return newState;
        default:
            return state;
    }
};

export default stripeReducer;
