const SET_USING_MOBILE = 'agent/setUsingMobile';

const setUsingMobileAction = (usingMobile: boolean) => {
    return {
        type: SET_USING_MOBILE,
        payload: usingMobile,
    };
};

export const setUsingMobile = (usingMobile: boolean) => (dispatch: any) => {
    dispatch(setUsingMobileAction(usingMobile));
    return;
};

const initialState = { usingMobile: false };

const mobileReducer = (state = initialState, action: any) => {
    let newState;
    switch (action.type) {
        case SET_USING_MOBILE:
            newState = { ...state };
            newState.usingMobile = action.payload;
            return newState;
        default:
            return state;
    }
};

export default mobileReducer;
