import { connect } from 'react-redux';
import { RootState } from '../../store';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { navigate_wrapper } from '../../utility_logic/navigate_wrapper';

const highlightDelay = 150;

const StepComponent = ({
    usingMobile,
    maxStep,
    currentStep,
    stepIndex,
    text,
    extendedText,
    navigatePath,
    showWhen,
    numberOfSteps,
}: {
    usingMobile: boolean;
    maxStep: number;
    currentStep: number;
    stepIndex: number;
    text: string;
    extendedText: string;
    navigatePath: string;
    showWhen: any;
    numberOfSteps: number;
}) => {
    const [highlight, setHighlight] = useState<boolean>(false);
    const [shortenText, setShortenText] = useState<boolean>(false);
    const windowWidth = useRef(window.innerWidth);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (windowWidth.current <= 1230) {
            setShortenText(true);
        }
    }, []);

    return (
        <>
            {showWhen(currentStep, stepIndex) && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        width: `${(100 / (numberOfSteps - 1) - 1) * 1}%`,
                        cursor: highlight ? 'pointer' : 'default',
                        paddingTop: usingMobile ? '3vh' : '2vh',
                    }}
                    onMouseEnter={() => {
                        if (maxStep >= stepIndex) setHighlight(true);
                    }}
                    onMouseLeave={() => {
                        if (maxStep >= stepIndex) setTimeout(() => setHighlight(false), highlightDelay);
                    }}
                    onClick={() => {
                        if (maxStep >= stepIndex) {
                            navigate_wrapper(navigate, location, navigatePath);
                        }
                    }}
                >
                    <div
                        style={{
                            fontFamily: 'Raleway',
                            fontWeight: '600',
                            fontSize: '0.75rem',
                            lineHeight: '14px',
                            color: currentStep === stepIndex ? '#212529' : maxStep >= stepIndex ? '#6C757D' : '#ADB5BD',
                            marginLeft: '1px',
                        }}
                    >
                        {!shortenText && extendedText} {text}
                    </div>
                    <div
                        style={{
                            height: '0.5vh',
                            width: '100%',
                            backgroundColor:
                                currentStep === stepIndex ? '#212529' : maxStep >= stepIndex ? '#6C757D' : '#E9ECEF',
                            borderRadius: '2px',
                            marginTop: '0.5vh',
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default connect((state: RootState) => ({ usingMobile: state.mobile.usingMobile }))(StepComponent);
