import { useEffect, useState } from 'react';
import LoadingSpinner from './loadingSpinner';
import LoadingText from './loadingText';

const LoadingSpinnerContainer = ({
    loadingText,
    active,
    usingMobile,
}: {
    loadingText: string;
    active: boolean;
    usingMobile: boolean;
}) => {
    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: usingMobile ? 'space-between' : 'center',
                alignItems: 'center',
                height: active ? (usingMobile ? '11vh' : '') : '0%',
            }}
            className="loading-container"
        >
            {active && (
                <>
                    <LoadingSpinner active={active} usingMobile={usingMobile} large={false}></LoadingSpinner>
                    <div style={{ height: '2vh' }}></div>
                    <LoadingText active={active} loadingText={loadingText} usingMobile={usingMobile}></LoadingText>
                </>
            )}
        </div>
    );
};

export default LoadingSpinnerContainer;
