import { RootState } from '../../store';
import { connect } from 'react-redux';
import StepComponent from './StepComponent';

type props = {
    maxStep: number;
    currentStep: number;
    onboardingPathsShowSteps: {
        [key: string]: boolean;
    };
};

const numberObj: { [key: number]: string } = {
    3: 'One',
    4: 'Two',
    5: 'Three',
    6: 'Four',
};

const steps = [
    {
        text: 'Sign Up',
        extendedText: '',
        navigatePath: '/signup',
        showWhen: (step: number, index: number) => true,
    },
    {
        text: 'Plan',
        extendedText: ' Select',
        navigatePath: '/planselect',
        showWhen: (step: number, index: number) => true,
    },
    {
        text: 'Electricity',
        extendedText: 'Connect',
        navigatePath: '/electric',
        showWhen: (step: number, index: number) => true,
    },
    {
        text: 'Gas',
        extendedText: 'Connect',
        navigatePath: '/gas',
        showWhen: (step: number, index: number) => true,
    },
    {
        text: 'Savings',
        extendedText: 'Find',
        navigatePath: '/confirm',
        showWhen: (step: number, index: number) => step <= index,
    },
    {
        text: 'Calculating...',
        extendedText: '',
        navigatePath: '/confirm',
        showWhen: (step: number, index: number) => step == index,
    },
];

const startStep = 3;

const StepTracker = ({ maxStep, currentStep, onboardingPathsShowSteps }: props) => {
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    {steps.map((step: any, index: number) => {
                        if (onboardingPathsShowSteps[step.navigatePath]) {
                            return (
                                <StepComponent
                                    maxStep={maxStep}
                                    currentStep={currentStep}
                                    stepIndex={index + 1}
                                    text={step.text}
                                    extendedText={step.extendedText}
                                    navigatePath={step.navigatePath}
                                    showWhen={step.showWhen}
                                    numberOfSteps={steps.length + 1 - startStep}
                                    key={'stepComponent' + index}
                                ></StepComponent>
                            );
                        }
                    })}
                </div>
                <div
                    style={{
                        fontFamily: 'Raleway',
                        fontSize: '1.25rem',
                        fontWeight: 'bold',
                    }}
                >
                    Step {numberObj[currentStep]}
                </div>
            </div>
        </>
    );
};

export default connect((state: RootState) => ({
    maxStep: state.step.maxStep,
    currentStep: state.step.currentStep,
}))(StepTracker);
