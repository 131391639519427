import React, { FormEvent, useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import PhoneNumberInput from './PhoneNumberInput';
import { matchIsValidTel } from 'mui-tel-input';
import LoadingSpinner from '../LoadingSpinnerContainer/loadingSpinner';
import { force_minimum_load } from '../../utility_logic/minimum_loading';
import clsx from 'clsx';

type props = {
    phoneNumber: string;
    setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
    phoneNumberSent: boolean;
    setPhoneNumberSent: React.Dispatch<React.SetStateAction<boolean>>;
    sendVerifText: (waitPromise: Promise<unknown> | null) => void;
    usingMobile: boolean;
};

const PhoneNumberForm = ({
    phoneNumber,
    setPhoneNumber,
    phoneNumberSent,
    setPhoneNumberSent,
    sendVerifText,
    usingMobile,
}: props) => {
    const [consent, setConsent] = useState(false);

    const [validPhoneNumber, setValidPhoneNumber] = useState<boolean | null>(null);

    const handleSubmit = async () => {
        setPhoneNumberSent(true);
        const waitPromise = force_minimum_load();

        const isValid = matchIsValidTel(phoneNumber);

        setValidPhoneNumber(isValid);

        if (isValid && phoneNumber) {
            sendVerifText(waitPromise);
        } else {
            await waitPromise;
            setPhoneNumberSent(false);
        }
    };

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && phoneNumber.replace(/\s/g, '').length === 12 && consent && !phoneNumberSent) {
                handleSubmit();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [phoneNumber, consent, phoneNumberSent]);

    return (
        <form
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: usingMobile ? 'space-between' : 'flex-start',
                alignItems: usingMobile ? 'center' : 'flex-start',
                width: usingMobile ? '100%' : '75%',
                height: usingMobile ? '54.5vh' : '',
                marginTop: usingMobile ? '6vh' : '5vh',
                marginBottom: '0px',
            }}
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div
                        style={{
                            fontFamily: 'Raleway',
                            fontSize: '0.875rem',
                            fontWeight: '500',
                            marginBottom: '1vh',
                            color: '#495057',
                        }}
                    >
                        Phone Number
                    </div>

                    <PhoneNumberInput
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        validPhoneNumber={validPhoneNumber}
                    />
                </div>

                <div style={{ height: usingMobile ? '4vh' : '3vh' }}></div>

                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        cursor: 'pointer',
                        justifyContent: 'space-between',
                        position: 'relative',
                    }}
                    onClick={() => setConsent(!consent)}
                >
                    <input
                        type="checkbox"
                        checked={consent}
                        readOnly={true}
                        style={{
                            height: '24px',
                            width: '24px',
                            margin: '0px 0px 0px 0px',
                            borderWidth: '0px',
                            marginTop: usingMobile ? '' : '2.5px',
                        }}
                    />
                    <label
                        style={{
                            fontFamily: 'Raleway',
                            fontSize: '0.6875rem',
                            lineHeight: '14px',
                            color: '#343A40',
                            width: '90%',
                            marginLeft: '37px',
                            userSelect: 'none',
                        }}
                    >
                        I agree to receive communications by text message about my inquiry. You may opt-out by replying
                        STOP or reply HELP for more information. Message frequency varies. Message and data rates may
                        apply. You may review our{' '}
                        <span
                            onClick={(e) => {
                                e.stopPropagation();
                                window.open('https://wattsons.io/privacy-policy', '_blank');
                            }}
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        >
                            privacy policy
                        </span>{' '}
                        to learn how you data is used.
                        {/* <span
                            onClick={(e) => {
                                e.stopPropagation();
                                window.open('https://wattsons.io/terms-of-services', '_blank');
                            }}
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        >
                            terms and conditions
                        </span>
                        . */}
                    </label>
                </div>
            </div>

            {!usingMobile && <div style={{ height: '4vh' }}></div>}

            <button
                type="submit"
                className={clsx('button submit full', {
                    active: phoneNumber.replace(/\s/g, '').length === 12 && consent,
                    loading: phoneNumberSent,
                    'mobile mobile-full': usingMobile,
                })}
                disabled={phoneNumber.replace(/\s/g, '').length !== 12 || !consent || phoneNumberSent}
            >
                {phoneNumberSent ? '' : 'START MY H.E.A.T. ASSESSMENT'}
                <LoadingSpinner active={phoneNumberSent} usingMobile={usingMobile} large={false}></LoadingSpinner>
            </button>
        </form>
    );
};

export default PhoneNumberForm;
