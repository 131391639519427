import LogInSignUpForm from '../LogInSignUpPage';
import * as stepActions from '../../store/step';
import { useAppDispatch } from '../../store';
import { useEffect } from 'react';

const HomePage = () => {
    const dispatch = useAppDispatch();

    return (
        <>
            <LogInSignUpForm></LogInSignUpForm>
            {/* <div
                style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        fontFamily: 'DunbarTextExBold, sans-serif',
                        fontWeight: '500',
                        fontSize: '50',
                        textAlign: 'center',
                        marginBottom: '10%',
                    }}
                >
                    Undergoing Maintenance
                </div>

                <div style={{ fontFamily: 'DunbarText, sans-serif', fontWeight: '400', fontSize: '24' }}>
                    Check back in a few days
                </div>
            </div> */}
        </>
    );
};

export default HomePage;
